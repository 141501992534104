<div class="navbar">
  <div class="navbar_top" role="tablist">
    <button
      mat-button
      role="navigation"
      aria-selected="false"
      [ngClass]="{
        'unselected-button': !selectedRoute?.includes('home'),
        'selected-button': selectedRoute?.includes('home'),
        'text-align-l': showText
      }"
      (click)="onClick('home', 1)">
      <mat-label *ngIf="showText" [attr.aria-current]="selectedPage === 1 ? true : false"> Home</mat-label>
    </button>
    <button
      mat-button
      role="navigation"
      aria-selected="false"
      *ngIf="authenticated"
      [ngClass]="{
        'unselected-button': !selectedRoute?.includes('console'),
        'selected-button': selectedRoute?.includes('console') || selectedRoute?.includes('app-details'),
        'text-align-l': showText
      }"
      (click)="onClick('console', 2)">
      <mat-label *ngIf="showText" [attr.aria-current]="selectedPage === 2 ? true : false">Console</mat-label>
    </button>
    <button
      mat-button
      role="navigation"
      aria-selected="false"
      [ngClass]="{
        'unselected-button': !selectedRoute?.includes('apis'),
        'selected-button': selectedRoute?.includes('apis'),
        'text-align-l': showText
      }"
      (click)="onClick('apis', 3)">
      <mat-label *ngIf="showText" [attr.aria-current]="selectedPage === 3 ? true : false">API Catalog</mat-label>
    </button>
    <button
      mat-button
      aria-label="Frequently Asked Questions"
      role="navigation"
      aria-selected="false"
      [ngClass]="{
        'unselected-button': !selectedRoute?.includes('faqs'),
        'selected-button': selectedRoute?.includes('faqs'),
        'text-align-l': showText
      }"
      (click)="onClick('faqs', 4)">
      <mat-label *ngIf="showText" [attr.aria-current]="selectedPage === 4 ? true : false">FAQ</mat-label>
    </button>
  </div>
  <div>
    <button mat-button *ngIf="authenticated" role="navigation" aria-selected="false" (click)="contactUs()">
      <img src="./assets/images/icons/contact_icon_large.svg" alt="Contact" width="25" height="25" />
      <mat-label *ngIf="showText"> Contact</mat-label>
    </button>
  </div>
</div>
