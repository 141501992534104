import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SsoService implements OnDestroy {
  private destroyed = new Subject();
  baseUrl: string;
  code: string;
  grantType = 'authorization_code';
  refreshType = 'refresh_token';
  redirectUri: string;
  subscription: Subscription;
  authExpires: Observable<any>;
  signal = new Subject();
  codeString = 'code';

  constructor(private http: HttpClient) {}

  // NOTE: This is the call before changing to below for Nicole
  // initSSO(): Observable<any> {
  //   const query = window.location.search;
  //   this.code = this.getQueryParam(query)[this.codeString];
  //   if (this.code) {
  //     console.log(`code:::${this.code}`);
  //     this.baseUrl = '/auth';
  //     this.redirectUri = window.location.origin;
  //     const url = `${this.baseUrl}?grant_type=${this.grantType}&redirect_uri=${this.redirectUri}&code=${this.code}`;
  //
  //     console.log(`url:::${url}`);
  //
  //     const headers = { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/x-www-form-urlencoded'};
  //     return this.http.post<any>(url, undefined, {headers});
  //   }
  // }

  // NOTE: This is the call that is Nicole ask to have put in so we can get the access_token which is passed to all calls
  initSSO(): Observable<any> {
    this.baseUrl = '/auth';
    const url = `${this.baseUrl}?grant_type=client_credentials`;
    const headers = { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/x-www-form-urlencoded' };
    return this.http.post<any>(url, undefined, { headers });
  }

  getQueryParam(query) {
    const qs = query.substring(query.indexOf('?') + 1).split('&');
    const result = {};
    for (let i = 0; i < qs.length; i++) {
      qs[i] = qs[i].split('=');
      result[qs[i][0]] = decodeURIComponent(qs[i][1]);
    }
    return result;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.destroyed.next('');
    this.destroyed.complete();
  }
}
