<div class="main-div">
  <kp-header></kp-header>
  <div class="content" [style.min-height.px]="outletHeight">
    <div class="outlet-div">
      <button mat-fab color="primary" aria-label="Back button" *ngIf="showBackButton()" (click)="onBackClicked()">
        <mat-icon>keyboard_backspace</mat-icon>
      </button>
      <router-outlet *ngIf="!isIframe"></router-outlet>
    </div>
  </div>
  <kp-footer></kp-footer>
</div>
