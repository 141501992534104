import { Component } from '@angular/core';
import { GlobalDialogService } from '../../services/global-dialog.service';

@Component({
  selector: 'kp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  currentYear = new Date().getFullYear();

  constructor(private globalDialogService: GlobalDialogService) {}

  onAccessibility(event: Event): void {
    this.globalDialogService.openAccessibility(event);
  }

  onPrivacy(event: Event): void {
    this.globalDialogService.openPrivacy(event);
  }
}
