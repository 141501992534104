import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetSelectedRoute } from '../../store/api-eco.actions';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { ApiEcoState } from '../../store/api-eco.state';
import { User } from '../../store';
import { Subject } from 'rxjs';

@Component({
  selector: 'kp-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  selectedRoute = '';
  showText: boolean;
  authenticated = false;
  isAdmin = false;
  selectedPage = 0;

  private destroyed = new Subject();

  constructor(
    private store: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(takeUntil(this.destroyed))
      .subscribe((event: NavigationEnd) => {
        const route = event.urlAfterRedirects.substr(event.urlAfterRedirects.indexOf('/') + 1);
        this.selectedRoute = route;
        this.store.dispatch(new SetSelectedRoute(route));
      });

    this.store
      .select(ApiEcoState.getUser)
      .pipe(takeUntil(this.destroyed))
      .subscribe((user: User) => {
        this.authenticated = user && user.isAuthenticated;
        this.isAdmin = user && user.userRole && user.userRole.toLowerCase() === 'admin';
      });

    this.store
      .select(ApiEcoState.getSelectedRoute)
      .pipe(takeUntil(this.destroyed))
      .subscribe((route: string) => {
        this.selectedRoute = route;
      });

    this.store
      .select(ApiEcoState.getShowNavbarText)
      .pipe(takeUntil(this.destroyed))
      .subscribe((show: boolean) => {
        this.showText = show;
      });
  }

  onClick(route: string, page: number): void {
    this.selectedPage = page;
    this.store.dispatch(new SetSelectedRoute(route));
  }

  getBaseRoute(): string {
    const index = this.selectedRoute.indexOf('/');
    return index > 0 ? this.selectedRoute.substr(0, index) : this.selectedRoute;
  }

  contactUs(): void {
    this.store.dispatch(new SetSelectedRoute('contact'));
  }

  ngOnDestroy(): void {
    this.destroyed.next('');
    this.destroyed.complete();
  }
}
