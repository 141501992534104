<div class="header">
  <h1 class="logo-div" [class.privacy-page]="isPrivacyPolicyPage === true" role="banner">
    <img class="logo" src="./assets/images/kplogo1277AE.svg" (click)="home()" alt="Kaiser Permanente" />
    <p class="site-text" *ngIf="!isPrivacyPolicyPage">|</p>
    <p class="site-text" *ngIf="!isPrivacyPolicyPage">Developer Portal</p>
  </h1>
  <div class="button-div" *ngIf="!isPrivacyPolicyPage">
    <form [formGroup]="loginForm" *ngIf="authenticated === false">
      <div class="action-plus-link-div">
        <div class="action-div">
          <button mat-stroked-button class="header-login-button" (click)="login()" role="button">Login</button>
          <button mat-stroked-button class="header-signup-button" (click)="createAccount($event)" role="button">
            Create an Account
          </button>
        </div>
        <p class="header-text">
          By using this website you agree to our
          <span
            class="header-link-text"
            role="Terms and Conditions"
            tabindex="0"
            (keydown.enter)="onTermsAndConditions($event)"
            (click)="onTermsAndConditions($event)"
            role="link">
            Terms and Conditions
          </span>
          <br />
        </p>
      </div>
    </form>
    <div class="action-div" *ngIf="authenticated">
      <img class="logo" src="./assets/images/icons/profile_circle_icon.svg" alt="Profile Circle Icon" role="banner" />
      <div class="button-menu-div">
        <button mat-button [matMenuTriggerFor]="menu" role="button">
          {{ displayName }}
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="editProfile()" role="button">Edit Profile</button>
          <button mat-menu-item (click)="resetPassword()" role="button">Reset Password</button>
          <button mat-menu-item (click)="updatePhoneNumber()" role="button">Update Phone Number</button>
          <button mat-menu-item (click)="logout()" role="button">Logout</button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
<div class="navbar-div" *ngIf="showNavBar" role="navigation">
  <kp-navbar style="width: 100%"></kp-navbar>
</div>
<div class="page-header-div" *ngIf="showPageHeader" role="heading">
  <h2 class="page-header-label">
    <img src="{{ pageHeaderImage }}" [attr.alt]="pageHeaderLabel" class="page-header-icon" />
    <mat-label> {{ pageHeaderLabel }}</mat-label>
  </h2>
  <button
    *ngIf="authenticated === false"
    class="primary-action-button"
    style="white-space: nowrap !important"
    (click)="createAccount($event)">
    Create an Account
  </button>
</div>
