const termsAndConditionsHtml = `
  <div class="termsAndConditions">
    <h1>KP API Developer Agreement</h1>
    THIS IS A LEGAL AGREEMENT BETWEEN YOU AND KAISER FOUNDATION HOSPITALS (“KFH”, “WE” OR “OUR”) GOVERNING YOUR USE OF THE DEVELOPER PORTAL AND THE KP APIS (DEFINED BELOW).
    BY CLICKING “I AGREE” OR ACCESSING THE DEVELOPER PORTAL OR USING THE KP APIS YOU ARE AGREEING TO BE BOUND BY THE TERMS OF THIS DEVELOPER AGREEMENT AND ANY OTHER
    APPLICABLE TERMS AND CONDITIONS POSTED ON KAISER PERMANENTE’S WEBSITE LOCATED AT <a href="http://www.kp.org/" target="_blank">WWW.KP.ORG</a> (COLLECTIVELY, THE “AGREEMENT”).
    IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, YOU MAY NOT ACCESS THE DEVELOPER PORTAL OR USE THE KP APIS.<br>
    <br>
    By entering into this Agreement, you affirm that you are at least 13 years old and of legal age to enter into this Agreement and are authorized to enter into this Agreement
    on behalf of your Company. No legal partnership or agency relationship is created between Kaiser Permanente and you or your Company by virtue of this Agreement.<br>
    <br>
    We may update this Agreement by posting the updated version(s) on this Website. Updated versions of the Agreement will apply to your use of the KP APIs occurring on or
    after the date of the last update. The "Last Updated" legend above indicates when this Agreement was last changed. You should periodically review this page to determine
    if this Agreement has been updated. Your continued use of the KP APIs following any updates to this Agreement shall constitute notice and acceptance of any such updates.<br>
    <br>
    <h2>PERMISSIBLE USE OF KP APIS</h2>
    We provide access to Our application programming interfaces (“APIs”), and their associated documentation
    and sandbox (collectively, the “KP APIs”) on the Developer Portal. We may update, change, discontinue or
    add KP APIs or functionality or features to the KP APIs in Our discretion with or without providing notice to you.<br>
    <br>
    Subject to the terms of this Agreement, KFH grants you a limited, non-sublicensable, non-assignable,
    non- transferable, royalty-free, non-exclusive license only to use the KP APIs as described in their applicable
    implementation guides. You may only access KP APIs that require registration if the application has been registered
    with KFH.You agree to comply with all applicable laws, regulations and governmental issuances.<br>
    <br>
    <h2>RESTRICTIONS</h2>
    You may not: (a) decompile, disassemble, reverse engineer, or otherwise attempt to derive, reconstruct, identify, or discover any source code, underlying ideas,
    or algorithms of the KP APIs by any means, except to the extent that the foregoing restriction is prohibited by applicable law; (b) remove any proprietary notices,
    labels, or marks from the KP APIs; (c) interrupt or attempt to interrupt the operation of the KP APIs in any way, including, without limitation, by restricting,
    inhibiting, or interfering with the ability of any other user to use the KP APIs (including by means of hacking or defacing any portion of the KP APIs, or by
    engaging in spamming, flooding, or other disruptive activities); (d) disrupt, interfere with, modify, bypass, or otherwise circumvent KP API functionality or features,
    limitations, security measures, technical processes, availability, integrity, or performance (or attempt the same); (e) transmit or attempt to transmit data over a KP API
    unless such transmission is authorized and formatted in accordance with applicable specifications in the KP API implementation guide; (f) transmit or otherwise make
    available through or in connection with the KP APIs any malicious, harmful or invasive code; (g) attempt to exceed KP API rate limits; (h) conduct security research
    on or testing against KP APIs, services, applications, systems, devices, or networks without prior written approval from KFH; or (i) use the KP APIs (1) for any unlawful
    purpose or in any manner not authorized or intended in the KP API implementation guide, (2) in any way that could pose a threat to, disrupt, interfere with, harm, or
    impair the KP APIs, KFH or other KFH services, applications, systems, devices, or networks, or Kaiser Permanente members’, patients’, customers’, or other users’ use
    of KP APIs, (3) in any manner that, in KFH’s reasonable determination, constitutes excessive or abusive usage, (4) to gain unauthorized access to any KFH service,
    application, system, device, or network, or (5) to transmit malicious code or exploit security flaws, vulnerabilities, or deficiencies.<br>
    <br>
    <h2>MONITORING</h2>
    Your use of this Website and the KP APIs may be monitored by KFH to ensure compliance with this Agreement. You consent to such monitoring.<br>
    <br>
    <h2>REPORTING SECURITY ISSUES</h2>
    You agree to promptly report to KFH any security flaws, vulnerabilities, or deficiencies identified through normal use of KP APIs by calling the Kaiser National
    Compliance Hotline at 1-(888)-774-9100, 711(TTY).  You may not publicly disclose security flaws, vulnerabilities, or deficiencies in the KP APIs or other KFH applications,
    systems, devices, or networks of which you become aware.<br>
    <br>
    <h2>ACCOUNTS/REGISTRATION</h2>
    You represent and warrant that any and all registration information you provide to KFH in connection with your access
    to the KP APIs is accurate, complete, and up to date. Any notices in connection with this Agreement or your use of
    the KP APIs will be provided by email to the address provided as part of your registration information. You agree
    to inform KFH promptly of any updates to your registration information. You are responsible for maintaining the
    confidentiality of any Passwords, keys, tokens, and user IDs issued to you by KFH in connection with your
    access to the KP APIs (collectively, “Credentials”) and agree not to provide them to any third party.
    You are responsible for all applications that are developed under your Credentials and any breach of
    security caused by your failure to maintain the confidentiality of your Credentials. You agree to notify
    KFH immediately in the event of loss, theft, unauthorized use or other compromise of the security
    or confidentiality of your Credentials.<br>
    <br>
    <h2>PROPRIETARY RIGHTS</h2>
    KFH or its licensors own the KP APIs and the content on this Website and all intellectual property rights therein. You may not use any Kaiser Permanente entity’s name,
    trademarks, service marks, tradenames, logos or other distinctive brand features except as necessary to comply with your obligation, above, and agree not to remove any
    proprietary notices, labels, or marks from the KP APIs, and, in any case, you may not use those notices, labels or marks to imply affiliation with or endorsement by
    Kaiser Permanente. You have only those rights to access and use the KP APIs as are expressly granted by KFH under this Agreement and all other rights in the KP APIs are
    reserved to KFH or its licensors. You acknowledge that these rights are valid and protected in all forms, media, and technologies existing now or hereinafter developed.
    “Kaiser Permanente” means the integrated health care delivery organization doing business as Kaiser Permanente including, without limitation, Kaiser Foundation Hospitals,
    Kaiser Foundation Health Plan, Inc., Kaiser Permanente Insurance Company, The Permanente Federation, the Permanente Medical Groups, and the subsidiaries, partners and
    successors of the foregoing.<br>
    <br>
    <h2>PRIVACY</h2>
    Your submission of information through the Website is governed by our Privacy Policy.<br>
    <br>
    <h2>RESPONSIBILITY FOR HARDWARE, SOFTWARE, TELECOMMUNICATIONS AND OTHER SERVICES</h2>
    You are responsible for obtaining, maintaining, and paying for all hardware, software, and all telecommunications and other services, needed for you to use the KP APIs.<br>
    <br>
    <h2>DISCLAIMER OF WARRANTY</h2>
    KFH AND ITS SERVICE PROVIDERS DISCLAIM ALL EXPRESS OR IMPLIED REPRESENTATIONS OR WARRANTIES REGARDING THE KP APIS, INFORMATION, CONTENT, SERVICES, FUNCTIONALITY, AND ANY
    OTHER RESOURCES AVAILABLE ON OR ACCESSIBLE THROUGH THIS WEBSITE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
    OR NON- INFRINGEMENT. ALL SUCH KP APIS, INFORMATION, CONTENT, SERVICES, FUNCTIONALITY AND RESOURCES ARE MADE AVAILABLE "AS IS" AND "AS AVAILABLE", AT YOUR SOLE RISK, WITHOUT
    WARRANTY OF ANY KIND. KFH DOES NOT WARRANT THAT THE WEBSITE OR KP APIS WILL BE ACCURATE OR OPERATE WITHOUT INTERRUPTION OR ERROR.<br>
    <br>
    <h2>LIMITATION OF LIABILITY</h2>
    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL KFH, KAISER PERMANENTE OR THEIR SERVICE PROVIDERS, LICENSORS OR RESPECTIVE EMPLOYEES, OFFICERS, DIRECTORS,
    AGENTS, AFFILIATES, SUPPLIERS, VENDORS, LICENSORS, CO-BRANDERS OR PARTNERS (COLLECTIVELY, THE “KAISER PERMANENTE PARTIES") BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL,
    PUNITIVE, INCIDENTAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM ANY LOSS OF USE, LOSS OF DATA, LOSS OF PROFITS, BUSINESS INTERRUPTION,
    LITIGATION, OR ANY OTHER PECUNIARY LOSS, WHETHER BASED ON BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY, OR OTHERWISE ARISING OUT OF OR IN ANY WAY
    CONNECTED WITH THE USE, OPERATION OR PERFORMANCE OF THE KP APIS, WITH THE DELAY OR INABILITY TO USE THE KP APIS, ANY DEFECTS IN THE KP APIS, OR WITH THE PROVISION OF, OR
    FAILURE TO MAKE AVAILABLE, ANY INFORMATION, SERVICES, PRODUCTS, MATERIALS, OR OTHER RESOURCES AVAILABLE ON OR ACCESSIBLE THROUGH THE KP APIS, EVEN IF ADVISED OF THE
    POSSIBILITY OF SUCH DAMAGES.<br>
    <br>
    You acknowledge and agree that the limitations set forth above are fundamental elements of this Agreement.<br>
    <br>
    <h2>INDEMNIFICATION</h2>
    You agree to indemnify, defend, and hold the Kaiser Permanente Parties harmless from any liability, loss, claim, and expense (including reasonable attorneys' fees)
    actually or allegedly related to or arising out of your use of the KP APIs or this Website, your use or disclosure of information obtained through the KP APIs, your
    violation of this Agreement, and/or your violation of the rights of any other person.<br>
    <br>
    <h2>TERM, TERMINATION, SUSPENSION AND REVOCATION</h2>
    This Agreement is effective until terminated by either party. If you no longer agree to be bound by this Agreement, you must cease your use of the KP APIs. If you breach
    any provision of this Agreement, then you may no longer use the KP APIs.<br>
    <br>
    KFH may suspend or revoke your Credentials or access to the KP APIs without prior notice for your failure to comply with this Agreement or if KFH determines that your
    access to the KP APIs would present an unacceptable level of risk to the security of KFH’s systems. KFH may terminate this Agreement if you fail to comply with its terms
    and, to the extent permitted by law, for any or no reason.<br>
    <br>
    If this Agreement is terminated for any reason, then: (a) this Agreement will continue to apply and be binding upon you in respect of your prior use of the KP APIs
    (and any unauthorized further use of the KP APIs); and (b) any rights granted to us under this Agreement will survive such termination.<br>
    <br>
    <h2>GENERAL LEGAL TERMS</h2>
    This Agreement constitutes the entire agreement between you and KFH with respect to its subject matter KFH’s failure to exercise or enforce any right or provision of
    this Agreement shall not constitute a waiver of such right or provision. If a court of competent jurisdiction rules that any provision of the Agreement is invalid,
    then that provision will be removed from the Agreement without affecting the rest of the Agreement and the remaining provisions will continue to be valid and enforceable.
    There are no third- party beneficiaries to this Agreement. The rights granted in this Agreement may not be assigned or transferred by You without the prior written
    approval of KFH. You may not delegate your responsibilities or obligations under this Agreement without the prior written approval of KFH. This Agreement shall be
    governed by the laws of the State of California without regard to its conflict of laws provisions. You agree to submit to the exclusive jurisdiction of the courts
    located within the county of Alameda, California to resolve any legal matter arising from this Agreement. KFH may, notwithstanding this, seek injunctive remedies in
    any jurisdiction.
  </div>
`;

export default termsAndConditionsHtml;
