import { Injectable } from '@angular/core';
import { AuthenticationResult, AuthError, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { BehaviorSubject, filter, Subject, takeUntil } from 'rxjs';
import { AuthData, LoginData, SignupData } from '../store';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { BrowserStorageService, BrowserStorageType } from './browser-storage.service';
import {
  AzureLogin,
  CreateUser,
  GetUserFromSession,
  Login,
  SetNotification,
  UpdateUser,
} from '../store/api-eco.actions';
import { Store } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { ProgressSpinnerDialogService } from './progress-spinner-dialog.service';
import { APIELogService } from './apie-log.service';
import { AlertMessages } from 'src/assets/data/alert-messages';

export const SESSION_CONTEXT_ID = 'session_context';
export const GLOBAL_CONTEXT_ID = 'global_context';

/**
 *
 */
interface IdTokenClaims extends AuthenticationResult {
  idTokenClaims: any;
}

/**
 *
 */
export enum ContextStatusType {
  LOGIN_IN_PROGRESS = 'loginInProgress',
  LOGGED_IN = 'loggedIn',
  LOGGED_IN_PENDING_REFRESH = 'loggedInPendingRefresh',
  UNAUTHORIZED = 'validated',
  AUTH_ERROR = 'authError',
  LOGGED_OUT = 'loggedOut',
  INITIALIZED = 'initialized',
  EMPTY = 'empty',
}

/**
 *
 */
export enum ContextUpdateType {
  STATUS = 'status',
  AUTH_DATA = 'authData',
  REFRESH = 'refresh',
  TOKEN = 'token',
  TOKEN_EXPIRY = 'tokenExpiry',
  USER_CONTEXT = 'userContext',
}

/**
 *
 */
export enum ContextActionType {
  PREINIT = 'preInit',
  INIT = 'init',
  UPDATE = 'validated',
  LOGOUT = 'loggedOut',
  LOGOUT_AND_CLEAR_STORAGE = 'loggedOut',
}

/**
 *
 */
export interface TokenInfo {
  token: string;
  tokenExpiry: any;
  authData: AuthData;
}

/**
 *
 */
export interface CommonContext extends TokenInfo {
  status: ContextStatusType;
  isPersistent: boolean;
  lastReload: string;
  lastReloadInTicks: number;
}

export type StorageContext = CommonContext;

export type SessionContext = StorageContext;

export type GlobalContext = StorageContext;

/**
 *
 */
export interface UserContext {
  session: SessionContext;
  global: GlobalContext;
}

/**
 *
 */
@Injectable({
  providedIn: 'root',
})
export class UserContextService {
  private _userContext: UserContext = {
    session: {
      isPersistent: false,
      token: undefined,
      tokenExpiry: undefined,
      authData: undefined,
      status: ContextStatusType.EMPTY,
    } as SessionContext,
    global: {
      isPersistent: false,
      token: undefined,
      tokenExpiry: undefined,
      authData: undefined,
      status: ContextStatusType.EMPTY,
    } as GlobalContext,
  } as UserContext;

  private _b2cPolicies: any;
  private _defaultSessionContext: SessionContext;
  private _defaultGlobalContext: GlobalContext;
  private _defaultCommonContext: CommonContext;
  private _destroyed: Subject<unknown>;
  private _logPrefix: string = environment.userContext.logMessagePrefix;

  private readonly _sessionContextId = `apie.${environment.environmentName}.${SESSION_CONTEXT_ID}`.toLowerCase();
  private readonly _globalContextId = `apie.${environment.environmentName}.${GLOBAL_CONTEXT_ID}`.toLowerCase();

  /**
   * Enables verbose logging; can be set from environment variables.
   */
  public verbose: boolean = environment.userContext.logging.userContextService;

  public userContext$ = new BehaviorSubject(this._userContext);

  /**
   *
   * @param store
   * @param browserStorageService
   * @param msalBroadcastService
   * @param authService
   * @param progressDialogService
   * @param logService
   */
  constructor(
    private store: Store,
    private browserStorageService: BrowserStorageService,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private progressDialogService: ProgressSpinnerDialogService,
    private logService: APIELogService
  ) {
    // Set B2C policies
    this._b2cPolicies = environment.b2cPolicies;

    const defaultStorageContext = {
      isPersistent: false,
      status: ContextStatusType.EMPTY,
      token: undefined,
      tokenExpiry: undefined,
      authData: undefined,
    } as StorageContext;

    this._defaultCommonContext = {
      status: ContextStatusType.EMPTY,
      token: undefined,
      tokenExpiry: undefined,
      authData: undefined,
    } as CommonContext;

    // Default context(s) state
    this._defaultSessionContext = defaultStorageContext as SessionContext;
    this._defaultGlobalContext = defaultStorageContext as GlobalContext;
  }

  /**
   *
   * @param destroyed
   */
  public initService(destroyed: Subject<unknown>) {
    this.logService.log(`Initializing User Context Service...`, 'info', `${this._logPrefix} -> INIT`);

    this._destroyed = destroyed;

    this.initUserContext();

    // Subsscriptions
    this.subscribeToUserDetailFlow();
    this.subscribeToResetPasswordFlow();
    this.subscribeToAuthFailure();
    this.subscribeToLoginFlow();
  }

  /**
   *
   */
  private initUserContext() {
    this.logService.log(`Initializing STORAGE Contexts`, 'info', `${this._logPrefix} -> INIT`);
    // IMPORTANT: Global init must happen before session init
    this.initGlobalStorageContext();
    this.initSessionStorageContext();

    // Update subscribers
    this.userContext$.next(this.getContext());
  }

  /**
   *
   */
  private async initSessionStorageContext() {
    this.logService.log(
      `Initializing SESSION STORAGE Context: ${this._sessionContextId}`,
      'info',
      `${this._logPrefix} -> INIT`
    );

    // get session context from storage
    this._userContext.session = this.browserStorageService.getItem(BrowserStorageType.SESSION, this._sessionContextId);

    // If session context is empty, default and initialize.
    if (!this._userContext.session) {
      this.logService.log(
        'SESSION STORAGE Context empty. Setting to default value...',
        'info',
        `${this._logPrefix} -> INIT`
      );

      this._userContext.session = this._defaultSessionContext;
      this._userContext.session.isPersistent = true;
      this._userContext.session.status = ContextStatusType.INITIALIZED;

      this.updateStorageContext(BrowserStorageType.SESSION, this._userContext.session);
    } else if (this._userContext.session.status === ContextStatusType.LOGGED_IN_PENDING_REFRESH) {
      const now = new Date();
      const maxReloadTimeInTicks = environment.userContext.browserMaxReloadTime;
      const lastReloadInTicks = this._userContext.global.lastReloadInTicks;
      const currentTimeInTicks = now.getTime();

      this.logService.log(`TICKS: Last Reload:\n${lastReloadInTicks} - Current: ${currentTimeInTicks}`, 'info');

      if (lastReloadInTicks + maxReloadTimeInTicks < currentTimeInTicks) {
        this.logService.log(
          `Session was previously closed and reopend in another tab. Clear old session:\n${JSON.stringify(
            this._userContext.session,
            null,
            '\t'
          )}`,
          'info'
        );

        this.updateContext(ContextUpdateType.STATUS, ContextStatusType.LOGGED_OUT, BrowserStorageType.ALL);
      } else {
        this.logService.log(
          `Browser re-load occured after login. Displaying progress spinner until session loads.`,
          'warning',
          `${this._logPrefix} -> RELOAD AFTER LOGIN ⏰`
        );
        this._userContext.session.status = ContextStatusType.LOGGED_IN;
        this.updateStorageContext(BrowserStorageType.SESSION, this._userContext.session);

        // Show spinner
        this.progressDialogService.showSpinner();
      }
    }
  }

  /**
   *
   * @param saveInStorage
   * @returns
   */
  private duplicateSessionContextFromGlobalContext(saveInStorage: boolean = true): SessionContext {
    // get global context from storage.
    this._userContext.global = this.browserStorageService.getItem(BrowserStorageType.LOCAL, this._globalContextId);

    // get session context from storage.
    this._userContext.session = this.browserStorageService.getItem(BrowserStorageType.SESSION, this._sessionContextId);

    if (sessionStorage.length === 0 || !this._userContext.session || !this._userContext.session.isPersistent) {
      if (
        this._userContext.global &&
        this._userContext.global.token !== undefined &&
        (this._userContext.global.status === ContextStatusType.LOGGED_IN ||
          this._userContext.global.status === ContextStatusType.LOGGED_IN_PENDING_REFRESH)
      ) {
        let sessionContext = {} as SessionContext;
        const today = new Date();

        const lastReloadInTicks = this._userContext.global.lastReloadInTicks;

        this.logService.log(`LAST RELOAD TICKS:\n${lastReloadInTicks} - CURRENT TICKS: ${today.getTime}`, 'error');

        if (lastReloadInTicks + 1000 < today.getTime()) {
          this.logService.log(
            `DO NOT DUPLICATE SESSION - clear global:\n${JSON.stringify(sessionContext, null, '\t')}`,
            'info'
          );
        }

        this.logService.log(
          `Session context or storage was empty but Global context(local storage) indicated session was still active. Applying Global context settings to Session context.`,
          'warning',
          `${this._logPrefix} -> INIT ⏰`
        );

        sessionContext = this._defaultSessionContext;
        sessionContext.token = this._userContext.global.token;
        sessionContext.tokenExpiry = this._userContext.global.tokenExpiry;
        sessionContext.status = this._userContext.global.status;
        sessionContext.authData = this._userContext.global.authData;
        sessionContext.lastReload = this._userContext.global.lastReload;
        sessionContext.lastReloadInTicks = this._userContext.global.lastReloadInTicks;
        sessionContext.isPersistent = true;

        this._userContext.session = sessionContext;

        this.logService.log(
          `Duplicating Session Context from Global Context:\n${JSON.stringify(sessionContext, null, '\t')}`,
          'info'
        );

        // Save session to storage
        if (saveInStorage) {
          this.browserStorageService.setItem(BrowserStorageType.SESSION, this._sessionContextId, sessionContext);
        }
      }
    }

    return this._userContext.session;
  }

  /**
   *
   */
  private initGlobalStorageContext(): void {
    this.logService.log(
      `Initializing GLOBAL STORAGE Context:  ${this._globalContextId}`,
      'info',
      `${this._logPrefix} -> INIT`
    );

    // Duplicate session context from Global context if in 'logged in' state.
    this.duplicateSessionContextFromGlobalContext();

    // If session is empty AND global session is empty, or not in a logged in state
    if (sessionStorage.length === 0 || !this._userContext.session || !this._userContext.session.isPersistent) {
      this.logService.log(
        `SESSION STORAGE Context empty. Flush and initialize GLOBAL STORAGE Context.`,
        'warning',
        `${this._logPrefix} -> INIT ⏰`
      );

      // Default if empty
      if (!this._userContext.global || this._userContext.global.isPersistent === false) {
        this._userContext.global = this._defaultGlobalContext;
        this._userContext.global.isPersistent = true;
      }

      // Update status to initialize (regardless if empty)
      this.updateContext(ContextUpdateType.STATUS, ContextStatusType.INITIALIZED, BrowserStorageType.LOCAL);
    } else {
      // If global context is empty, default and initialize.
      if (!this._userContext.global) {
        this._userContext.global = this._defaultGlobalContext;
        this._userContext.global.isPersistent = true;

        this.updateContext(ContextUpdateType.STATUS, ContextStatusType.INITIALIZED, BrowserStorageType.LOCAL);
      }
    }
  }

  /**
   * Update instance context from browser storage. If context is not available, a non persisent context will be assigned.
   * @returns
   */
  public getTokenInfo(): TokenInfo {
    const userContext = this.getContext();

    if (
      sessionStorage.length > 0 &&
      userContext.session.isPersistent === true &&
      userContext.session.token !== undefined &&
      userContext.session.authData !== undefined &&
      userContext.session.authData.access_token !== undefined
    ) {
      return {
        token: userContext.session.token,
        tokenExpiry: userContext.session.tokenExpiry,
        authData: userContext.session.authData,
      } as TokenInfo;
    } else if (userContext.global.status !== ContextStatusType.EMPTY) {
      return {
        token: userContext.global.token,
        tokenExpiry: userContext.global.tokenExpiry,
        authData: userContext.global.authData,
      } as TokenInfo;
    }
    // If userContext.global.status === ContextStatus.EMPTY
    return {
      token: userContext.global.token,
      tokenExpiry: userContext.global.tokenExpiry,
      authData: userContext.global.authData,
    } as TokenInfo;
  }

  /**
   * Update instance context from browser storage. If context is not available, a non persisent context will be assigned.
   * @returns
   */
  public getContext(): UserContext {
    // Session context
    this._userContext.session = this.browserStorageService.getItem(BrowserStorageType.SESSION, this._sessionContextId);

    // Local context
    this._userContext.global = this.browserStorageService.getItem(BrowserStorageType.LOCAL, this._globalContextId);

    if (!this._userContext.session) {
      this._userContext.session = this._defaultSessionContext;
      this.logService.log(
        `SESSION STORAGE Context not found: ${this._sessionContextId}..`,
        'warning',
        `${this._logPrefix} -> GET CONTEXT ⏰`
      );
    }

    if (!this._userContext.global) {
      this.logService.log(
        `Global Storage context not found: ${this._globalContextId}..`,
        'warning',
        `${this._logPrefix} -> GET CONTEXT ⏰`
      );
      this._userContext.global = this._defaultGlobalContext;
    }

    this.userContext$.next(this._userContext);

    return this._userContext;
  }

  /**
   *
   * @param property
   * @param value
   * @param type
   * @param withRefreshFromStorage
   */
  public updateContext(
    property: ContextUpdateType,
    value: ContextStatusType | UserContext | AuthData | string,
    type: BrowserStorageType = BrowserStorageType.ALL,
    withRefreshFromStorage: boolean = true
  ): void {
    if (withRefreshFromStorage) this.refreshContext();
    // this.refreshContext();
    let isPersistent = true;

    const today = new Date();

    const lastReloadInTicks = today.getTime();
    const lastReloadDate = `${today.getDate()}-${today.getMonth()}-${today.getFullYear()} @ ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;

    if (type === BrowserStorageType.ALL || BrowserStorageType.SESSION) {
      switch (property) {
        case ContextUpdateType.TOKEN:
          this._userContext.session.token = value as string;
          break;
        case ContextUpdateType.TOKEN_EXPIRY:
          this._userContext.session.tokenExpiry = value as string;
          break;
        case ContextUpdateType.STATUS:
          this._userContext.session.status = value as ContextStatusType;
          break;
        case ContextUpdateType.REFRESH:
          this._userContext.session.status = ContextStatusType.LOGGED_IN_PENDING_REFRESH;
          this._userContext.session.lastReload = lastReloadDate;
          this._userContext.session.lastReloadInTicks = lastReloadInTicks;

          break;
        case ContextUpdateType.AUTH_DATA:
          this._userContext.session.authData = value as AuthData;
          break;
        case ContextUpdateType.USER_CONTEXT:
          this._userContext.session = (value as UserContext).session;
          break;
        default:
          isPersistent = false;
          break;
      }

      if (isPersistent) {
        this._userContext.session.isPersistent = isPersistent;
      }

      this.updateStorageContext(type, this._userContext.session);
    }

    if (type === BrowserStorageType.ALL || BrowserStorageType.LOCAL) {
      switch (property) {
        case ContextUpdateType.TOKEN:
          this._userContext.global.token = value as string;
          break;
        case ContextUpdateType.TOKEN_EXPIRY:
          this._userContext.global.tokenExpiry = value as string;
          break;
        case ContextUpdateType.STATUS:
          const status = value as ContextStatusType;
          this._userContext.global.status = status;
          break;
        case ContextUpdateType.REFRESH:
          this._userContext.global.status = ContextStatusType.LOGGED_IN_PENDING_REFRESH;
          this._userContext.global.lastReload = lastReloadDate;
          this._userContext.global.lastReloadInTicks = lastReloadInTicks;
          break;
        case ContextUpdateType.AUTH_DATA:
          this._userContext.global.authData = value as AuthData;
          break;
        case ContextUpdateType.USER_CONTEXT:
          this._userContext.global = (value as UserContext).global;
          break;
        default:
          isPersistent = false;
          break;
      }

      if (isPersistent) {
        this._userContext.global.isPersistent = isPersistent;
      }

      this.updateStorageContext(type, this._userContext.global);
    }

    this.userContext$.next(this._userContext);
  }

  /**
   *
   */
  public logout() {
    this.resetContext('Logout out of session');
  }

  /**
   *
   */
  public preInitContext() {
    this.resetContext('Initializing session');
  }

  /**
   *
   */
  public resetContextAfterLogin() {
    this.logService.log(`Login...`, 'info', `${this._logPrefix} -> LOGIN`);
    this._userContext.session.isPersistent = true;
  }

  /**
   *
   */
  private refreshContext(): void {
    this.getContext();
  }

  /**
   *
   * @param type
   * @param context
   */
  private updateStorageContext(type: BrowserStorageType, context: StorageContext): void {
    switch (type) {
      case BrowserStorageType.ALL:
        this.browserStorageService.setItem(BrowserStorageType.SESSION, this._sessionContextId, context);
        this.browserStorageService.setItem(BrowserStorageType.LOCAL, this._globalContextId, context);
        break;

      case BrowserStorageType.SESSION:
        this.browserStorageService.setItem(BrowserStorageType.SESSION, this._sessionContextId, context);
        break;

      case BrowserStorageType.LOCAL:
        this.browserStorageService.setItem(BrowserStorageType.LOCAL, this._globalContextId, context);
        break;

      default:
        break;
    }
  }

  /**
   *
   */
  private resetUserContext(clearStorage: boolean = false) {
    this.logService.log(`Resetting user context for session and local storage`, 'info', `${this._logPrefix} -> RESET`);

    if (clearStorage) {
      this.browserStorageService.clear(BrowserStorageType.ALL);
    } else {
      this.browserStorageService.removeItem(BrowserStorageType.SESSION, this._sessionContextId);

      this.browserStorageService.removeItem(BrowserStorageType.LOCAL, this._globalContextId);
    }

    this._userContext = {
      session: this._defaultSessionContext,
      global: this._defaultGlobalContext,
    } as UserContext;
  }

  /**
   *
   */
  private subscribeToUserDetailFlow() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroyed)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();

        const currentAcount = this.authService.instance.getActiveAccount();
        if (currentAcount) {
          this.logService.log(
            `Retreived MSAL context from app memory (session or local)...`,
            'success',
            `${this._logPrefix} -> LOGGED_IN 🍺  `
          );

          this.updateContext(ContextUpdateType.STATUS, ContextStatusType.LOGGED_IN, BrowserStorageType.ALL);

          this.store.dispatch(new GetUserFromSession(currentAcount));
        }
      });
  }

  /**
   *
   */
  private subscribeToResetPasswordFlow() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || msg.eventType === EventType.LOGIN_SUCCESS
        ),
        takeUntil(this._destroyed)
      )
      .subscribe((result: EventMessage) => {
        // If returning from successful password reset request, force logout to complete process
        const payload: IdTokenClaims = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        this.logService.log(`Return from Password Reset...`, 'info');
        if (payload.idTokenClaims?.tfp === this._b2cPolicies.names.resetPassword) {
          this.store.dispatch(
            new SetNotification({
              error: false,
              warning: false,
              message: AlertMessages.SuccessResetPasswordFromLogin,
              title: 'Success',
              duration: 5000,
            })
          );
          this.logService.log(`LOGOUT of Auth Service.`, 'warning');
          setTimeout(() => {
            this.authService.logout();
          }, 5000);
        }
        return result;
      });
  }

  /**
   *
   */
  private subscribeToAuthFailure() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this._destroyed)
      )
      .subscribe((result: EventMessage) => {
        if (result.error instanceof AuthError) {
          // Check for forgot password error
          // Learn more about AAD error codes at https://docs.microsoft.com/azure/active-directory/develop/reference-aadsts-error-codes
          const userContext = this.getContext();

          if (userContext.global.status === ContextStatusType.LOGIN_IN_PROGRESS) {
            this.progressDialogService.showSpinner();
          }

          this.updateContext(ContextUpdateType.STATUS, ContextStatusType.UNAUTHORIZED, BrowserStorageType.ALL);

          this.logService.log(
            `Auth error message:\n${result.error.message}`,
            'error',
            `${this._logPrefix} -> AUTH ERROR 🚨 🚨 🚨`
          );

          if (result.error.message.includes('AADB2C90118')) {
            // login request with reset authority
            const resetPasswordFlowRequest = {
              scopes: ['openid'],
              authority: this._b2cPolicies.authorities.resetPassword.authority,
            };
            this.store.dispatch(new AzureLogin(resetPasswordFlowRequest));
          }
          if (result.error.message.includes('AADB2C90091')) {
            // login request with reset authority
            const resetPasswordFlowRequest = {
              scopes: ['openid'],
              authority: this._b2cPolicies.authorities.signIn.authority,
            };

            this.logService.log(
              `Azure: Reset Password Flow (Request)`,
              'success',
              `${this._logPrefix} -> AZ RESET 🍺 `
            );
            this.store.dispatch(new AzureLogin(resetPasswordFlowRequest));
          }
        }
      });
  }

  /**
   *
   */
  private subscribeToLoginFlow() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        )
      )
      .subscribe({
        next: (result: EventMessage) => {
          const userContext = this.getContext();

          // Display spinner if logging in.
          if (userContext.global.status === ContextStatusType.LOGIN_IN_PROGRESS) {
            this.progressDialogService.showSpinner();
          }

          const payload: IdTokenClaims = result.payload as AuthenticationResult;

          this.authService.instance.setActiveAccount(payload.account);
          const loginData: LoginData = {
            email: payload.idTokenClaims?.username, //emails[0],
            homeAccountId: payload.account.homeAccountId,
            idToken: payload.idToken,
          };
          // catching edit profile
          if (payload.idTokenClaims?.acr === this._b2cPolicies.names.editProfile) {
            const updateUserData = {
              firstName: payload.idTokenClaims?.given_name,
              lastName: payload.idTokenClaims?.family_name,
              displayName: payload.idTokenClaims?.name, //`${payload.idTokenClaims?.given_name} ${payload.idTokenClaims?.family_name}`,
              email: payload.idTokenClaims?.username, //emails[0],
              phoneNumber: payload.idTokenClaims?.strongAuthenticationPhoneNumber,
              organizationName: payload.idTokenClaims?.extension_business,
              country: payload.idTokenClaims?.country,
              homeAccountId: payload.account.homeAccountId,
              idToken: payload.idToken,
            };
            this.store.dispatch(new UpdateUser(updateUserData));
          }
          if (payload.idTokenClaims?.isForgotPassword === true) {
            this.store.dispatch(
              new SetNotification({
                error: false,
                warning: false,
                message: AlertMessages.SuccessResetPassword,
                title: 'Success',
                duration: 5000,
              })
            );
          }
          if (payload.idTokenClaims?.acr === this._b2cPolicies.names.updatePhoneNumber) {
            const updatePhoneData = {
              phoneNumber: payload.idTokenClaims?.strongAuthenticationPhoneNumber,
              email: payload.idTokenClaims?.username, //emails[0],
              homeAccountId: payload.account.homeAccountId,
              idToken: payload.idToken,
              phoneUpdateOnly: true,
            };
            this.store.dispatch(new UpdateUser(updatePhoneData));
          }
          if (payload.idTokenClaims?.newUser === true && result.eventType === EventType.LOGIN_SUCCESS) {
            // make signup request to backend
            const signupData: SignupData = {
              firstName: payload.idTokenClaims?.given_name,
              lastName: payload.idTokenClaims?.family_name,
              displayName: payload.idTokenClaims?.name, //`${payload.idTokenClaims?.given_name} ${payload.idTokenClaims?.family_name}`,
              email: payload.idTokenClaims?.username, //emails[0],
              phoneNumber: payload.idTokenClaims?.strongAuthenticationPhoneNumber,
              organizationName: payload.idTokenClaims?.extension_business,
              country: payload.idTokenClaims?.country,
              homeAccountId: payload.account.homeAccountId,
              idToken: payload.idToken,
            };
            this.store.dispatch(new CreateUser(signupData));
          } else {
            this.resetContextAfterLogin();
            this.updateContext(ContextUpdateType.STATUS, ContextStatusType.LOGGED_IN, BrowserStorageType.ALL);

            this.logService.log(
              'Updating User Context after login success...',
              'success',
              `${this._logPrefix} -> LOGIN 🍺  `
            );

            this.store.dispatch(new Login(loginData));
          }
        },
        error: error => console.log(error),
      });
  }

  /**
   *
   */
  private checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      this.logService.log(`Setting Active Azure Account`, 'info', `${this._logPrefix} -> LOGIN`);
    }
  }

  /**
   *
   * @param message
   */
  private resetContext(message: string) {
    this.logService.log(`${message}...`, 'success', `${this._logPrefix} -> LOGOUT 🍺 `);

    this.resetUserContext(true);

    // NOTE: Discuss proper logout methodology
    // this.authService.instance.logout(<EndSessionRequest>{})
    this.userContext$.next(this._userContext);
  }
}
