import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { GlobalDialogComponent } from './global-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { GlobalDialogService } from '../../services/global-dialog.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [CommonModule, MatIconModule, MatDialogModule, MatCheckboxModule, MatButtonModule],
  declarations: [GlobalDialogComponent],
  exports: [GlobalDialogComponent],
  providers: [GlobalDialogService],
})
export class TermsDialogModule {}
