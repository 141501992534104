import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ApiEcoState } from './store/api-eco.state';
import { User } from './store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, OnDestroy {
  private authenticated = false;
  private destroyed = new Subject();

  constructor(
    private router: Router,
    private store: Store
  ) {
    this.store
      .select(ApiEcoState.getUser)
      .pipe(takeUntil(this.destroyed))
      .subscribe((user: User) => {
        this.authenticated = user && user.isAuthenticated;
      });
  }

  canActivateChild() {
    if (this.authenticated) {
      return true;
    }
    this.router.navigate(['/home']);
    return false;
  }

  canActivate() {
    return this.canActivateChild();
  }

  ngOnDestroy(): void {
    this.destroyed.next('');
    this.destroyed.complete();
  }
}
