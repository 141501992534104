import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'kp-global-dialog',
  templateUrl: 'global-dialog.component.html',
  styleUrls: ['global-dialog.component.scss'],
})
export class GlobalDialogComponent {
  @ViewChild('document')
  public document: ElementRef;

  public content: string | SafeHtml;
  public title: string;
  public buttonLabel: string;
  public showCancel = false;
  public cancelLabel: string;
  public showDownload = true;
  public showIcon = false;
  public iconName: string;
  public showSignup = false;
  public centerContent = true;
  notChecked = true;
  onAgreeLabel = 'OK.Select this button to close the modal';

  public showButtonsRight = false;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<GlobalDialogComponent>,
    public sanitizer: DomSanitizer
  ) {
    this.title = data?.title;
    this.content = this.sanitizer.bypassSecurityTrustHtml(data?.content);
    this.buttonLabel = data?.buttonLabel;
    this.showCancel = data?.showCancel;
    this.cancelLabel = data?.cancelLabel || 'Cancel';
    this.showButtonsRight = data?.showButtonsRight;
    this.showDownload = data ? data.showDownload : true;
    this.showIcon = data?.showIcon;
    this.iconName = data?.iconName;
    this.showSignup = data?.showSignup;
    this.centerContent = data?.centerContent;
    if (data && data.title) {
      this.onAgreeLabel = 'OK.Select this button to close the ' + this.title + ' modal';
    }
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }

  public onAgree(): void {
    this.dialogRef.close(true);
  }

  public signUp(): void {
    this.dialogRef.close(true);
  }

  public doanloadDocument(): void {
    this.document.nativeElement.click();
  }

  changeCheck(event): void {
    this.notChecked = !event.checked;
  }
}
