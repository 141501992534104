import { Component, Inject, isDevMode, OnDestroy, OnInit } from '@angular/core';
import {
  AzureLogout,
  GetExternalApiData,
  InitSso,
  RemoveNotifications,
  SetSelectedRoute,
} from './store/api-eco.actions';
import { Store } from '@ngxs/store';
import { AuthData, Notification } from './store';
import { ApiEcoState } from './store/api-eco.state';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';
import { AuthenticationResult } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { TitleService } from './services/title.service';
import { ProgressSpinnerDialogService } from './services/progress-spinner-dialog.service';
import { ContextStatusType, ContextUpdateType, UserContextService } from './services/user-context.service';
import { HostListener } from '@angular/core';
import { APIELogService } from './services/apie-log.service';
import { BrowserStorageType } from './services/browser-storage.service';
import { AlertService } from './services/alert.service';

interface IdTokenClaims extends AuthenticationResult {
  idTokenClaims: any;
}

@Component({
  selector: 'kp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  timedOut = false;
  lastPing?: Date = null;
  grantType = 'authorization_code';
  authData: AuthData;
  subscription: Subscription;
  authExpires: Observable<any>;
  signal = new Subject();
  selectedRoute = 'home';
  outletHeight: number;
  isIframe = false;
  b2cPolicies: any;
  getExternalApiDataCalled = false;
  applicationErrorSubstring = 'application with this name already exists';
  applicationErrorTitle = 'Duplicate Application Name';

  private destroyed = new Subject();
  private allowedIdleMinutes = 25;

  /**
   *
   * @param store
   * @param toasterService
   * @param idle
   * @param keepalive
   * @param router
   * @param location
   * @param titleService
   * @param document
   * @param progressDialogService
   * @param userContextService
   * @param logService
   */
  constructor(
    private store: Store,
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private location: Location,
    private titleService: TitleService,
    @Inject(DOCUMENT) public document,
    private progressDialogService: ProgressSpinnerDialogService,
    private userContextService: UserContextService,
    private logService: APIELogService,
    private alertService: AlertService
  ) {
    this.b2cPolicies = environment.b2cPolicies;
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {
    const context = this.userContextService.getContext();
    if (context.global?.tokenExpiry && Date.now() >= context.global.tokenExpiry * 1000) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
    this.userContextService.initService(this.destroyed);

    this.getExternalApiData();

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(takeUntil(this.destroyed))
      .subscribe((event: NavigationEnd) => {
        const route = event.urlAfterRedirects.substr(event.urlAfterRedirects.indexOf('/') + 1);
        this.selectedRoute = route;
        this.store.dispatch(new SetSelectedRoute(route));
        window.scrollTo(0, 0);
        this.outletHeight = window.innerHeight - 85;
        this.progressDialogService.hideSpinner();
      });

    this.store
      .select(ApiEcoState.getSelectedRoute)
      .pipe(takeUntil(this.destroyed))
      .subscribe((route: string) => {
        route && this.router.navigate([route]);
      });

    this.initIdleWatcher();
    this.subscribeToNotifications();
  }

  /**
   *
   * @param event Catches unload event when refreshing in a logged-in state.
   * @returns
   */
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event) {
    const context = this.userContextService.getContext();

    const hideConfirmationDialog = true; //environment.userContext.hideDialogOnWindowClose;

    this.logService.log(`Host Listener Event: ${JSON.stringify(event, null, '\t')}`, 'info');

    if (context.session.status === ContextStatusType.LOGGED_IN) {
      this.userContextService.updateContext(ContextUpdateType.REFRESH, undefined, BrowserStorageType.ALL);
      this.logService.log(`Brower Refresh occuring..`, 'error', `USER_CONTEXT -> BROWSER REFRESH`);
      return hideConfirmationDialog;
    }

    return hideConfirmationDialog;
  }

  /**
   *
   */
  onBackClicked(): void {
    this.location.back();
  }

  /**
   *
   * @returns
   */
  showBackButton(): boolean {
    const showOnRoutes = ['login', 'reset-password', 'contact', 'api-subscribe', 'app-details'];
    return showOnRoutes.includes(this.selectedRoute);
  }

  /**
   *
   */
  subscribeToNotifications(): void {
    this.store
      .select(ApiEcoState.getNotifications)
      .pipe(takeUntil(this.destroyed))
      .subscribe((notifications: Notification[]) => {
        if (notifications && notifications.length) {
          notifications.forEach((notification: Notification) => {
            let options;
            if (notification.duration >= 0) {
              options = notification.duration > 0 ? { timeOut: notification.duration } : { disableTimeOut: true };
            }
            if (notification.error) {
              this.alertService.showErrorAlert(notification, options);
            } else if (notification.warning) {
              this.alertService.showWarningAlert(notification, options);
            } else {
              this.alertService.showSuccessAlert(notification, options);
            }
          });
          this.alertService.setAttributeForToastrForScreenReaders();
          this.store.dispatch(new RemoveNotifications(notifications));
        }
      });
  }

  /**
   *
   */
  initIdleWatcher(): void {
    this.idle.setIdle(this.allowedIdleMinutes * 60); // Amount of time user is idle before warning displays
    this.idle.setTimeout(1); // Amount of time for logout warning to display before logging out
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // Default interrupts, like clicks, scrolls, touches to the document

    this.idle.onIdleEnd.subscribe(() => {
      this.resetIdle();
    });

    this.idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      this.store.dispatch(new AzureLogout(null));
      this.userContextService.logout();
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
  }

  /**
   *
   */
  resetIdle(): void {
    this.idle.watch();
    this.timedOut = false;
  }

  /**
   *
   */
  getExternalApiData() {
    if (!Object(isDevMode)()) {
      this.store.dispatch(new InitSso());
      this.store
        .select(ApiEcoState.getAuthData)
        .pipe(takeUntil(this.destroyed))
        .subscribe((authData: AuthData) => {
          this.authData = authData;
          if (this.authData && this.authData.access_token && !this.getExternalApiDataCalled) {
            this.store.dispatch(new GetExternalApiData());
            this.getExternalApiDataCalled = true;
          }
        });
    } else {
      this.store.dispatch(new GetExternalApiData());
    }
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.titleService.setDocTitle('api-ecosystem');
    this.destroyed.next('');
    this.destroyed.complete();
  }
}
