export const AlertMessages = {
  HttpErrorResponse: 'There is an issue with the service. Please try again later.',
  SuccessResetPasswordFromLogin: 'Password has been reset successfully. Please sign-in with your new password.',
  SuccessResetPassword: 'Password changed successfully.',
  ErrorResetPassword: 'An error occurred resetting your password. Please try again.',
  SuccessUserRegistration: 'Registration successful.',
  ErrorUserRegistration: 'Registration failed.',
  SuccessUserUpdate: 'Update successful.',
  ErrorUserUpdate: 'Update failed.',
  SuccessApiAdded: 'API added to your Application(s). Please see Details for additional information.',
  SuccessApisAdded: 'API(s) added to your Application(s). Please see Details for additional information.',
  SuccessEmail: 'Your email was sent successfully.',
  '404': 'The requested service or page not found. Please try again',
  '400': 'The server cannot process this request. Please check your request and try again.',
  '408': 'Request timed out',
  '502': 'Bad Gateway',
  TimedOut: 'You have been logged out due to inactivity. Please log back in to continue your work.',
  DuplicateAppMessage: 'An application with this name already exists. Please use a different name and try again.',
  DuplicateAppTitle: 'Duplicate Application Name',
  AccountLocked: 'Your account has been locked. Contact your support person to unlock it, then try again.',
};
