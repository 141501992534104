import { BrowserCacheLocation } from '@azure/msal-browser';

export const environment = {
  production: true,
  environmentName: 'UAT',
  apiUrl: 'https://uat-apieextbff-bff-1.apiecosystem.aksnp.azure.kp.org',
  b2cPolicies: {
    names: {
      signUpSignIn: 'B2C_1_susi_test',
      // signIn: 'B2C_1_signin_test',
      signIn: 'B2C_1A_Non_Prod_SignIn_UsernameOrEmail',
      resetPassword: 'B2C_1_passwordreset_test',
      // signUp: 'B2C_1_signup_test',
      signUp: 'B2C_1A_Non_Prod_SignUp_UsernameOrEmail',
      // editProfile: 'B2C_1_editprofile_test'
      editProfile: 'b2c_1a_non_prod_profileedit_usernameoremail',
      updatePhoneNumber: 'b2c_1a_non_prod_profileedit_phonenumber',
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://apiedev.b2clogin.com/apiedev.onmicrosoft.com/B2C_1_susi_test',
      },
      signUp: {
        // authority: 'https://apiedev.b2clogin.com/apiedev.onmicrosoft.com/B2C_1_signup_test',
        authority: 'https://apiedev.b2clogin.com/apiedev.onmicrosoft.com/B2C_1A_Non_Prod_SignUp_UsernameOrEmail',
      },
      signIn: {
        // authority: 'https://apiedev.b2clogin.com/apiedev.onmicrosoft.com/B2C_1_signin_test',
        authority: 'https://apiedev.b2clogin.com/apiedev.onmicrosoft.com/B2C_1A_Non_Prod_SignIn_UsernameOrEmail',
      },
      resetPassword: {
        authority: 'https://apiedev.b2clogin.com/apiedev.onmicrosoft.com/B2C_1_passwordreset_test',
      },
      editProfile: {
        // authority: 'https://apiedev.b2clogin.com/apiedev.onmicrosoft.com/B2C_1_editprofile_test',
        authority: 'https://apiedev.b2clogin.com/apiedev.onmicrosoft.com/B2C_1A_Non_Prod_ProfileEdit_UsernameOrEmail',
      },
      updatePhoneNumber: {
        authority: 'https://apiedev.b2clogin.com/apiedev.onmicrosoft.com/B2C_1A_Non_Prod_ProfileEdit_PhoneNumber',
      },
    },
    authorityDomain: 'apiedev.b2clogin.com',
  },
  clientId: 'db3b0aaa-8d27-4c67-88f7-54a3d8c2e2b3',
  isIE: window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1,
  userContext: {
    browserMaxReloadTime: 2000,
    hideDialogOnWindowClose: true,
    userCacheLocation: BrowserCacheLocation.SessionStorage,
    logMessagePrefix: 'USER_CONTEXT',
    logging: {
      userContextService: true,
      storageService: false,
    },
  },
};
