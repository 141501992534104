import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginData, User } from '../store';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  loginVals: any;

  constructor(private http: HttpClient) {}

  register(loginData: LoginData): Observable<any> {
    return this.http.post<LoginData>('/api/users/self/selfRegister', { ...loginData });
  }

  updatePassword(loginData: LoginData): Observable<any> {
    return this.http.put<LoginData>('/api/users/self/password', { notify: true, ...loginData });
  }

  login(loginData: LoginData): Observable<any> {
    return this.http.post('/api/ext/users/login', loginData);
  }

  getCachedUser(savedUserToken): Observable<any> {
    return this.http
      .get(`/api/ext/users`, {
        headers: new HttpHeaders({
          'x-user': savedUserToken,
        }),
      })
      .pipe(map((result: User) => result));
  }

  updateUser(user: any, userToken: string): Observable<any> {
    return this.http
      .put(`/api/ext/users`, user, {
        headers: new HttpHeaders({
          'x-user': userToken,
        }),
      })
      .pipe(map((result: any) => result));
  }

  logout(): Observable<any> {
    return this.http.post<LoginData>('/api/ext/users/logout', {}).pipe(map((result: any) => result));
  }
}
