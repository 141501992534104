<div class="subscribe-main">
  <form [formGroup]="appsForm">
    <div class="subscribe-title-div">
      <img
        *ngIf="apiData.visibility === 'Partner'"
        src="./assets/images/icons/lock_solid.svg"
        width="48px"
        height="48px"
        alt="Secure" />
      <h3 class="subscribe-title">{{ apiData.name }}</h3>
    </div>
    <p class="subscribe-instructions">{{ apiMessageText }}</p>
    <p class="subscribe-choose-text">Choose application:</p>
    <div class="subscribe-form">
      <div class="subscribe-form-group-div">
        <mat-checkbox *ngFor="let app of applicationsData" color="primary" formControlName="{{ app.id }}"
          >{{ app.name }}
        </mat-checkbox>
      </div>
    </div>
  </form>
  <div class="actions-div">
    <div class="subscribe-email-div">
      <span>You will receive email updates on planned maintenance, outages, and other API-specific information.</span>
    </div>
    <div class="button-div">
      <button class="secondary-action-button" (click)="onCancel()">Cancel</button>
      <button
        *ngIf="!appsForm.errors"
        class="primary-action-button"
        (click)="onSubmit()"
        attr.aria-label="{{
          apiData.visibility === 'Public'
            ? 'OK.Select this button to submit'
            : 'Request Access.Select this button to request access for api'
        }}">
        {{ apiData.visibility === 'Public' ? 'OK' : 'Request Access' }}
      </button>
      <button
        *ngIf="appsForm.errors"
        class="primary-disabled-action-button"
        attr.aria-label="{{
          apiData.visibility === 'Public' ? 'OK.This button is disabled' : 'Request Access.This button is disabled'
        }}">
        {{ apiData.visibility === 'Public' ? 'OK' : 'Request Access' }}
      </button>
    </div>
  </div>
</div>
