import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
// import { timingSafeEqual } from 'crypto';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { APIELogService } from '../../services/apie-log.service';
import { BrowserStorageType } from '../../services/browser-storage.service';
import { GlobalDialogService } from '../../services/global-dialog.service';
import {
  ContextStatusType,
  ContextUpdateType,
  UserContext,
  UserContextService,
} from '../../services/user-context.service';
import { LoginData, User } from '../../store';
import { AzureLogin, AzureLogout, SetSelectedRoute } from '../../store/api-eco.actions';
import { ApiEcoState } from '../../store/api-eco.state';

@Component({
  selector: 'kp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  b2cPolicies: any;
  authenticated: boolean;
  currentRoute = '';
  displayName: string;
  showNavBar = true;
  showPageHeader = false;
  pageHeaderImage: string;
  pageHeaderLabel: string;
  loginData: LoginData = {};
  loginForm: FormGroup;
  user: User;
  loginInProgress = true;
  userContext: UserContext;
  isPrivacyPolicyPage: boolean;

  private destroyed = new Subject();

  constructor(
    private store: Store,
    private termsEthicsDialogService: GlobalDialogService,
    private userContextService: UserContextService,
    private logService: APIELogService
  ) {
    this.b2cPolicies = environment.b2cPolicies;
  }

  ngOnInit(): void {
    this.logService.log('Initializing Header', 'info', 'HEADER -> INIT');
    const userContext = this.userContextService.getContext();
    this.loginInProgress =
      userContext.global.status === ContextStatusType.LOGIN_IN_PROGRESS ||
      userContext.global.status === ContextStatusType.LOGGED_IN_PENDING_REFRESH
        ? true
        : false;
    this.loginForm = new FormGroup({
      loginAction: new FormControl('login'),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    });
    this.store
      .select(ApiEcoState.getUser)
      .pipe(takeUntil(this.destroyed))
      .subscribe((user: User) => {
        if (user) {
          this.user = user;
          this.authenticated = user.isAuthenticated;
          this.displayName = user.displayName;
        }
      });
    this.store
      .select(ApiEcoState.getSelectedRoute)
      .pipe(takeUntil(this.destroyed))
      .subscribe((route: string) => {
        this.setRouteValues(route);
      });
  }

  setRouteValues(route: string): void {
    this.currentRoute = route;
    switch (true) {
      case route === 'console':
        this.isPrivacyPolicyPage = false;
        this.showNavBar = true;
        this.showPageHeader = true;
        this.pageHeaderImage = './assets/images/icons/console_icon.svg';
        this.pageHeaderLabel = 'Console';
        break;
      case route?.includes('apis'):
        this.isPrivacyPolicyPage = false;
        this.showNavBar = true;
        this.showPageHeader = true;
        this.pageHeaderImage = './assets/images/icons/apps_greycircle_icon_large.svg';
        this.pageHeaderLabel = 'API Catalog';
        break;
      case route === 'faqs':
        this.isPrivacyPolicyPage = false;
        this.showNavBar = true;
        this.showPageHeader = true;
        this.pageHeaderImage = './assets/images/icons/FAQs_icon_cir.svg';
        this.pageHeaderLabel = 'FAQ';
        break;
      case route === 'privacy-policy/KPHA':
        this.isPrivacyPolicyPage = true;
        this.showNavBar = false;
        this.showPageHeader = false;
        this.pageHeaderImage = './assets/images/icons/FAQs_icon_cir.svg';
        this.pageHeaderLabel = 'Privacy Policy';
        break;
      default:
        this.isPrivacyPolicyPage = false;
        this.showNavBar = true;
        this.showPageHeader = false;
    }
  }

  get formControls() {
    return this.loginForm ? this.loginForm.controls : undefined;
  }

  login(): void {
    this.logService.log(
      'Clearing Storage Contexts data before login Redirect',
      'info',
      'USER CONTEXT: -> HEADER LOGIN FLOW'
    );

    this.userContextService.preInitContext();

    this.userContextService.updateContext(
      ContextUpdateType.STATUS,
      ContextStatusType.LOGIN_IN_PROGRESS,
      BrowserStorageType.ALL
    );

    const userFlowRequest = {
      scopes: ['openid'],
      authority: this.b2cPolicies.authorities.signIn.authority,
      prompt: 'login',
    };

    this.store.dispatch(new AzureLogin(userFlowRequest));
  }

  logout(): void {
    this.userContextService.logout();
    // localStorage.clear();
    this.store.dispatch(new AzureLogout(null));
  }
  editProfile(): void {
    this.userContextService.updateContext(
      ContextUpdateType.STATUS,
      ContextStatusType.LOGIN_IN_PROGRESS,
      BrowserStorageType.ALL
    );
    // localStorage.setItem('loginInProgress','true');
    const editProfileFlowRequest = {
      scopes: ['openid'],
      authority: this.b2cPolicies.authorities.editProfile.authority,
    };
    this.store.dispatch(new AzureLogin(editProfileFlowRequest));
  }

  resetPassword(): void {
    this.userContextService.updateContext(
      ContextUpdateType.STATUS,
      ContextStatusType.LOGIN_IN_PROGRESS,
      BrowserStorageType.ALL
    );
    // localStorage.setItem('loginInProgress','true');
    const resetPasswordFlowRequest = {
      scopes: ['openid'],
      authority: this.b2cPolicies.authorities.resetPassword.authority,
    };
    this.store.dispatch(new AzureLogin(resetPasswordFlowRequest));
  }
  updatePhoneNumber(): void {
    this.userContextService.updateContext(
      ContextUpdateType.STATUS,
      ContextStatusType.LOGIN_IN_PROGRESS,
      BrowserStorageType.ALL
    );
    const updatePhoneNumberRequest = {
      scopes: ['openid'],
      authority: this.b2cPolicies.authorities.updatePhoneNumber.authority,
    };
    this.store.dispatch(new AzureLogin(updatePhoneNumberRequest));
  }

  createAccount(event: Event): void {
    const dialogRef = this.termsEthicsDialogService.openSignupTermsAndConditions(event);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const signupFlowRequest = {
          scopes: ['openid'],
          authority: this.b2cPolicies.authorities.signUp.authority,
          prompt: 'login',
        };
        this.store.dispatch(new AzureLogin(signupFlowRequest));
      }
    });
  }

  home(): void {
    if (this.currentRoute !== 'privacy-policy/KPHA') {
      this.store.dispatch(new SetSelectedRoute('home'));
    }
  }

  forgotPassword(): void {
    this.store.dispatch(new SetSelectedRoute('reset-password'));
  }

  onTermsAndConditions(event: Event): void {
    this.termsEthicsDialogService.openTermsAndConditions(event);
  }

  ngOnDestroy(): void {
    this.destroyed.next('');
    this.destroyed.complete();
  }
}
