import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProgressSpinnerComponent } from '../components/progress-spinner/progress-spinner.component';

@Injectable()
export class ProgressSpinnerDialogService {
  constructor(private dialog: MatDialog) {}

  dialogRef: MatDialogRef<ProgressSpinnerComponent>;

  showSpinner() {
    this.dialogRef = this.dialog.open(ProgressSpinnerComponent, {
      panelClass: 'transparent',
      disableClose: true,
    });
    setTimeout(() => {
      this.dialogRef.close();
    }, 10000);
  }

  hideSpinner() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
