import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { APIELogService } from './apie-log.service';

export enum BrowserStorageType {
  LOCAL = 'local',
  SESSION = 'session',
  COOKIE = 'cookie',
  NONE = 'none',
  ALL = 'all',
}

@Injectable({
  providedIn: 'root',
})

/**
 * Browser Storage Service
 * Provides methods to get, set, remove, clear local, session or cookie storage items.
 */
export class BrowserStorageService {
  constructor(
    private log: APIELogService,
    private cookieService: CookieService
  ) {}

  public verbose: boolean = environment.userContext.logging.storageService;

  /**
   * Set item in given storage type
   * @param type
   * @param key
   * @param value
   */
  setItem(type: BrowserStorageType, key: string, value: any) {
    const message = `setItem(): Storage set for key [${key}]...`;
    try {
      switch (type) {
        case BrowserStorageType.LOCAL:
          localStorage.setItem(key, JSON.stringify(value));
          this.storageLog(type, message);
          break;
        case BrowserStorageType.SESSION:
          sessionStorage.setItem(key, JSON.stringify(value));
          this.storageLog(type, message);
          break;
        case BrowserStorageType.COOKIE:
          // this.cookieService.
          break;
        default:
          this.storageLog(type, `setItem(): Storage not implemented for Cookie or other types currently.`, true);
          break;
      }
    } catch (error) {
      this.storageLog(type, `setItem(): ${error}`, true);
    }
  }

  /**
   * Get item from given storage type
   * @param type
   * @param key
   * @returns
   */
  getItem(type: BrowserStorageType, key: string): any {
    const message = `getItem(): Storage retreived for key [${key}]...`;
    let storageValue: any;

    try {
      switch (type) {
        case BrowserStorageType.LOCAL:
          storageValue = JSON.parse(localStorage.getItem(key));
          break;

        case BrowserStorageType.SESSION:
          storageValue = JSON.parse(sessionStorage.getItem(key));
          break;

        case BrowserStorageType.COOKIE:
        default:
          this.storageLog(type, `getItem(): Storage not implemented for Cookie or other types currently.`, true);
          break;
      }
    } catch (error) {
      this.storageLog(type, `getItem(): ${error}`, true);
    }

    // Logging
    if (storageValue) {
      this.storageLog(type, `${message}\n${JSON.stringify(storageValue, null, '\t')}`);
    }

    return storageValue;
  }

  /**
   * Remove item for given storage type
   * @param type
   * @param key
   */
  removeItem(type: BrowserStorageType, key: string) {
    const message = `removeItem(): Storage removed for key [${key}]...`;

    try {
      switch (type) {
        case BrowserStorageType.LOCAL:
          localStorage.removeItem(key);
          this.storageLog(type, message);
          break;

        case BrowserStorageType.SESSION:
          sessionStorage.removeItem(key);
          this.storageLog(type, message);
          break;

        case BrowserStorageType.COOKIE:
        default:
          this.storageLog(type, `removeItem(): Storage not implemented for Cookie or other types currently.`, true);
          break;
      }
    } catch (error) {
      this.storageLog(type, `removeItem(): ${error}`, true);
    }
  }

  private storageLog(type: BrowserStorageType, message: string, isError: boolean = false) {
    const service = 'BrowserStorageService';
    const serviceType = type.toUpperCase();
    const logMessage = `[${serviceType}]: ${message}`;

    if (this.verbose) {
      this.log.log(logMessage, isError ? 'error' : 'info', 'BROWSER_STORAGE');
    }
  }
  /**
   * Clear storage for give storage type
   * @param type
   */
  public clear(type: BrowserStorageType) {
    try {
      const message = 'clear(): Storage cleared...';

      switch (type) {
        case BrowserStorageType.ALL:
          sessionStorage.clear();
          this.storageLog(BrowserStorageType.SESSION, message);

          localStorage.clear();
          this.storageLog(BrowserStorageType.LOCAL, message);
          break;

        case BrowserStorageType.LOCAL:
          localStorage.clear();
          this.storageLog(type, message);
          break;

        case BrowserStorageType.SESSION:
          sessionStorage.clear();
          this.storageLog(type, message);
          break;

        case BrowserStorageType.COOKIE:
        default:
          this.storageLog(type, `clear(): Storage not implemented for Cookie or other types currently.`, true);
          break;
      }
    } catch (error) {
      this.storageLog(type, `clear(): ${error}`, true);
    }
  }
}
