export enum ColorEnum {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  START = 'start',
  END = 'end',
}

export enum DebugLevelEnum {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  FATAL = 'error',
  START = 'start',
  END = 'end',
}

export class APIELogService {
  constructor() {}

  /**
   *
   * @param msg
   * @param color
   */
  public log(msg: string, color: string, prefix: string = 'UserContext'): void {
    color = color || 'black';
    let bgc = 'White';

    msg = `[${prefix}] ${msg}`;

    switch (color) {
      case 'success':
        color = 'Green';
        bgc = 'LimeGreen';
        break;
      case 'info':
        color = 'DodgerBlue';
        // bgc = 'Turquoise';
        break;
      case 'error':
        color = 'Red';
        // bgc = 'Black';
        break;
      case 'start':
        color = 'OliveDrab';
        bgc = 'PaleGreen';
        break;
      case 'warning':
        color = 'Tomato';
        // bgc = 'Black';
        break;
      case 'end':
        color = 'Orchid';
        bgc = 'MediumVioletRed';
        break;
      default:
        color = color;
    }

    if (typeof msg == 'object') {
      console.log(msg);
    } else if (typeof color == 'object') {
      console.log('%c' + msg, 'color: PowderBlue;font-weight:bold; background-color: RoyalBlue;');
      console.log(color);
    } else {
      console.log('%c' + msg, 'color:' + color + ';font-weight:bold; background-color: ' + bgc + ';');
    }
  }
}
