import { Injectable, isDevMode, OnDestroy } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { AzureLogin, AzureLogout, SetAuthData, SetNotification, SetSelectedRoute } from '../store/api-eco.actions';
import { ApiEcoState } from '../store/api-eco.state';
import { AuthData, User } from '../store';
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'src/environments/environment';
import { ProgressSpinnerDialogService } from './progress-spinner-dialog.service';
import { ContextStatusType, ContextUpdateType, UserContextService } from './user-context.service';
import { APIELogService } from './apie-log.service';
import { AlertMessages } from 'src/assets/data/alert-messages';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor, OnDestroy {
  private destroyed = new Subject();
  authData: AuthData;
  authUser: User;
  apiUrl: string;
  b2cPolicies: any;
  constructor(
    private store: Store,
    private progressDialogService: ProgressSpinnerDialogService,
    private userContextService: UserContextService,
    private logService: APIELogService
  ) {
    this.b2cPolicies = environment.b2cPolicies;
    this.apiUrl = environment.apiUrl;
    this.store
      .select(ApiEcoState.getAuthData)
      .pipe(takeUntil(this.destroyed))
      .subscribe((authData: AuthData) => {
        this.authData = authData;
      });
    this.store
      .select(ApiEcoState.getUser)
      .pipe(takeUntil(this.destroyed))
      .subscribe((user: User) => {
        this.authUser = user;
      });
  }
  getAdditionalHeaders(req: HttpRequest<any>): any {
    const headers = { 'x-correlation-id': uuidv4() };
    if (this.authUser.userToken !== '') {
      if (req.url.indexOf('login') === -1) {
        // Check for expired token
        this.checkOrRefreshToken(this.authUser.userToken);
      }
      headers['x-user'] = this.authUser.userToken;
    }
    try {
      if (req.headers && req.headers?.has('x-user')) {
        headers['x-user'] = req.headers?.get('x-user');
      }
    } catch (error) {
      console.log(error, 'error adding reu headers X-USER in http interceptor');
    }
    return headers;
  }

  checkOrRefreshToken(token: string) {
    const tokenInfo = this.userContextService.getTokenInfo();
    const savedTokenExipry = tokenInfo.tokenExpiry;

    if (savedTokenExipry < Math.floor(Date.now() / 1000)) {
      // Refresh token if expired
      const userFlowRequest = {
        scopes: ['openid'],
        authority: this.b2cPolicies.authorities.signIn.authority,
      };

      this.logService.log(
        `Session expired. Logout and clear context for TOKEN[${token}].`,
        'warning',
        `HTTP -> TOKEN-EXPIRED`
      );

      // Logout, clear session and global contexts
      this.userContextService.logout();

      this.store.dispatch(new SetSelectedRoute('home'));
      this.store.dispatch(new AzureLogin(userFlowRequest));
    }
  }

  // checkOrRefreshToken(token: string) {
  //   const savedTokenExipry = JSON.parse(sessionStorage.getItem(token));
  //   if (savedTokenExipry < Math.floor(Date.now() / 1000)) {
  //     // Refresh token if expired
  //     const userFlowRequest = {
  //       scopes: ['openid'],
  //       authority: this.b2cPolicies.authorities.signIn.authority
  //     };
  //     sessionStorage.removeItem(savedTokenExipry);
  //     this.store.dispatch(new SetSelectedRoute('home'));
  //     this.store.dispatch(new AzureLogin(userFlowRequest));
  //   }
  // }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newUrl;
    if (Object(isDevMode)() && req.url.indexOf('assets') === -1) {
      newUrl = this.apiUrl + req.url;
      req = req.clone({
        withCredentials: false,
        url: newUrl ? newUrl : req.url,
        headers: new HttpHeaders({
          ...this.getAdditionalHeaders(req),
        }),
      });
    }

    // this.logService.log(`Is in DEV MODE ${JSON.stringify(!Object(isDevMode)() && req.url.indexOf('/api') !== -1, null, '\t')}`, 'error', 'DEV CHECK')

    if (!Object(isDevMode)() && req.url.indexOf('/api') !== -1) {
      if (!this.authData || !this.authData.access_token) {
        const tokenInfo = this.userContextService.getTokenInfo();

        if (!tokenInfo || !tokenInfo.authData || !tokenInfo.authData.access_token) {
          this.logService.log(
            'Authorization access token was not found in Global Context. Unable to login',
            'error',
            `HTTP INT -> AUTH ERROR  🚨 🚨 🚨`
          );
          return;
        } else {
          this.logService.log(
            'Authorization access token was not found. Retrieving authorization data from Global Context...',
            'warning',
            `USER_CONTEXT -> AUTH WARNING ⏰`
          );
          this.logService.log(
            `Auth data: \n${JSON.stringify(tokenInfo.authData, null, '\t')}`,
            'info',
            'HTTP INT -> AUTH DATA'
          );

          this.authData = tokenInfo.authData; // Should be removed if store updated successfully.

          // Update store.
          this.store.dispatch(new SetAuthData(tokenInfo.authData));
        }
      }

      req = req.clone({
        withCredentials: true,
        url: newUrl ? newUrl : req.url,
        headers: new HttpHeaders({
          Authorization: `${this.authData.token_type} ${this.authData.access_token}`,
          ...this.getAdditionalHeaders(req),
        }),
      });
    } else {
      req = req.clone({
        withCredentials: true,
        url: newUrl ? newUrl : req.url,
      });
    }

    return next.handle(req).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse && req.body && req.body.notify) {
          this.store.dispatch(
            new SetNotification({
              error: false,
              warning: false,
              message: 'Action successful',
              title: 'Success',
            })
          );
        }
      }),
      catchError((err: any) => {
        this.progressDialogService.hideSpinner();
        const errDetail = err.error ? err.error.details : undefined;
        if (err instanceof HttpErrorResponse) {
          try {
            let errorMessage = errDetail ? errDetail : err.message;
            if (Array.isArray(err.error)) {
              errorMessage = '';
              err.error.forEach(errorMessageVal => (errorMessage += `${errorMessageVal.msg + '\n'}`));
            }
            if (errorMessage.toLowerCase().indexOf('password') !== -1) {
              this.store.dispatch(new SetSelectedRoute('login'));
            }

            switch (err.status) {
              case 400:
                errorMessage = err.error.details
                  ? err.error.details
                  : err.error.message
                  ? err.error.message
                  : AlertMessages['400'];
                //err.error.details.includes(AlertMessages['DuplicateAppMessage'])
                // || err.error.details.includes(AlertMessages['AccountLocked']) ? err.error.details : err.error.message
                // ? err.error.message : AlertMessages['400'];
                this.store.dispatch(
                  new SetNotification({
                    error: true,
                    warning: false,
                    message: errorMessage,
                    title: 'Error',
                    duration: 0,
                  })
                );
                break;

              case 401:
                this.logService.log('Re-login required', 'warning', 'USER_CONTEXT -> LOGIN_FLOW  ⏰');
                this.userContextService.updateContext(ContextUpdateType.STATUS, ContextStatusType.LOGIN_IN_PROGRESS);
                const userFlowRequest = {
                  scopes: ['openid'],
                  authority: this.b2cPolicies.authorities.signIn.authority,
                  prompt: 'login',
                };
                this.store.dispatch(new AzureLogin(userFlowRequest));
                break;

              case 403:
                this.logService.log('Re-login required', 'warning', 'USER_CONTEXT -> LOGIN_FLOW  ⏰');
                this.userContextService.updateContext(ContextUpdateType.STATUS, ContextStatusType.LOGIN_IN_PROGRESS);
                const userRequest = {
                  scopes: ['openid'],
                  authority: this.b2cPolicies.authorities.signIn.authority,
                  prompt: 'login',
                };
                this.store.dispatch(new AzureLogin(userRequest));
                break;

              case 404:
                // commented this out as we are handling session timeout clear storages on app.component.html
                // const context = this.userContextService.getContext();
                if (err.url.includes('api/ext/users')) {
                  // if (context.global?.tokenExpiry && Date.now() >= context.global.tokenExpiry * 1000) {
                  //   this.userContextService.logout();
                  //   localStorage.clear();
                  //   sessionStorage.clear();
                  //   this.store.dispatch(new AzureLogout(null));
                  //   this.store.dispatch(
                  //     new SetNotification({
                  //       error: false,
                  //       warning: true,
                  //       message: AlertMessages.TimedOut,
                  //       title: 'Session Timed Out',
                  //       duration: 5000,
                  //     })
                  //   );

                  //   setTimeout(() => {
                  //     const userRequest = {
                  //       scopes: ['openid'],
                  //       authority: this.b2cPolicies.authorities.signIn.authority,
                  //       prompt: 'login',
                  //     };
                  //     this.store.dispatch(new AzureLogin(userRequest));
                  //   }, 5000);
                  // }
                } else {
                  this.store.dispatch(new SetSelectedRoute('home'));
                  this.store.dispatch(
                    new SetNotification({
                      error: true,
                      warning: false,
                      message: err.error.details
                        ? err.error.details
                        : err.error.message
                        ? err.error.message
                        : AlertMessages['404'],
                      title: 'Error',
                      duration: 0,
                    })
                  );
                }
                break;

              case 408:
                this.store.dispatch(new SetSelectedRoute('console'));
                break;
              case 502:
                this.store.dispatch(new SetSelectedRoute('console'));
                this.store.dispatch(
                  new SetNotification({
                    error: true,
                    warning: false,
                    message: err.error.details
                      ? err.error.details
                      : err.error.message
                      ? err.error.message
                      : AlertMessages['502'],
                    title: 'Error',
                    duration: 0,
                  })
                );
                break;
              default:
                const errMessage = err.error.details.includes('Internal Server Error')
                  ? AlertMessages.HttpErrorResponse
                  : err.error.message
                  ? err.error.message
                  : err.error.details
                  ? err.error.details
                  : AlertMessages.HttpErrorResponse;
                this.store.dispatch(
                  new SetNotification({
                    error: true,
                    warning: false,
                    message: errMessage,
                    title: err.name,
                    duration: 0,
                  })
                );
                break;
            }
          } catch (e) {
            // log error
          }
        }
        return of(err);
      })
    );
  }

  ngOnDestroy(): void {
    this.destroyed.next('');
    this.destroyed.complete();
  }
}
