import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProgressSpinnerComponent } from './progress-spinner.component';
import { ProgressSpinnerDialogService } from '../../services/progress-spinner-dialog.service';
@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule],
  declarations: [ProgressSpinnerComponent],
  exports: [ProgressSpinnerComponent],
  providers: [ProgressSpinnerDialogService],
})
export class ProgressSpinnerModule {}
