import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Application, ExternalApiDetails } from '../../store';
import { requireCheckboxesCheckedValidator } from './require-checkboxes-checked.validator';
import { Location } from '@angular/common';
import { SubscribeToOneApiForManyApplications } from '../../store/api-eco.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'kp-api-subscribe',
  templateUrl: './api-subscribe.component.html',
  styleUrls: ['./api-subscribe.component.scss'],
})
export class ApiSubscribeComponent implements OnInit {
  @Input() apiData: ExternalApiDetails;
  @Input() applicationsData: Application[];
  appsForm: FormGroup;
  apiMessageText: string;

  constructor(
    private location: Location,
    private store: Store
  ) {}

  ngOnInit(): void {
    if (this.apiData) {
      if (this.apiData.visibility === 'Partner') {
        this.apiMessageText =
          'This API provides protected information and approval is required.  After submitting this request, check your email for status.';
      } else {
        this.apiMessageText = 'Confirm your applications below to add this API to your Console.';
      }
    }

    if (this.applicationsData) {
      const defaultSelectedCount: number = this.applicationsData.filter(
        (app: Application) => !!app.isSubscribed
      ).length;
      const minimumRequired: number = defaultSelectedCount + 1;
      this.appsForm = new FormGroup({}, requireCheckboxesCheckedValidator(minimumRequired));

      this.applicationsData.forEach((appsData: any) => {
        const hasRevokedPartnerAPI = appsData.hasRevoked && this.apiData.visibility.toLowerCase() === 'partner';
        if (hasRevokedPartnerAPI) {
          appsData.name = appsData.name + ' (Revoked)';
        } else if (appsData.isSubscribed) {
          appsData.name = appsData.name + ' (Already Added)';
        }
        this.appsForm.addControl(
          appsData.id,
          new FormControl({
            value: appsData.isSubscribed || hasRevokedPartnerAPI,
            disabled: appsData.isSubscribed || hasRevokedPartnerAPI,
          })
        );
      });

      // set application checkboxed to checked if only one
      if (this.applicationsData.length === 1) {
        Object.keys(this.appsForm.controls).forEach(key => {
          const control = this.appsForm.controls[key];
          if (!control.disabled) {
            control.setValue(true);
          }
        });
      }
    }
  }

  onCancel(): void {
    this.location.back();
  }

  onSubmit(): void {
    const applicationIds: string[] = [];
    Object.keys(this.appsForm.controls).forEach(key => {
      const control = this.appsForm.controls[key];

      if (control.value === true && !control.disabled) {
        applicationIds.push(key);
      }
    });
    this.store.dispatch(new SubscribeToOneApiForManyApplications(this.apiData.id, applicationIds));
  }
}
