<div class="global-dialog-container">
  <div mat-dialog-title>
    {{ title }}
    <div class="title-close" tabindex="0" (keydown.enter)="onCancel()">
      <mat-icon (click)="onCancel()">close</mat-icon>
      <span class="title-close__label" mat-dialog-close>Close</span>
    </div>
  </div>
  <div mat-dialog-content [ngClass]="centerContent ? 'global-dialog-content-center' : 'global-dialog-content'">
    <mat-icon *ngIf="showIcon">{{ iconName }}</mat-icon>
    <div [innerHTML]="content"></div>
  </div>
  <div class="global-dialog-actions" [class.global-dialog-actions--right]="showButtonsRight">
    <div class="download-document" *ngIf="this.showDownload" tabindex="0" (keydown.enter)="doanloadDocument()">
      <mat-icon (click)="doanloadDocument()" aria-label="Download this document in PDF">download</mat-icon>
      <div (click)="doanloadDocument()">Download this document</div>
      <a
        #document
        href="/assets/data/terms-and-conditions.pdf"
        aria-label="Download this document in PDF"
        download="terms-and-conditions"
        [style.visibility]="'hidden'"
        >Download this document</a
      >
    </div>
    <mat-checkbox *ngIf="showSignup" (change)="changeCheck($event)">I agree to the Terms and Conditions</mat-checkbox>
    <div *ngIf="!showSignup" class="action-container">
      <button *ngIf="showCancel" class="secondary-action-button" (click)="onCancel()" cdkFocusInitial>
        {{ cancelLabel }}
      </button>
      <button class="primary-action-button" (click)="onAgree()" attr.aria-label="{{ onAgreeLabel }}" cdkFocusInitial>
        {{ buttonLabel }}
      </button>
    </div>
    <div *ngIf="showSignup" class="action-container">
      <button *ngIf="showCancel" class="secondary-action-button" (click)="onCancel()" cdkFocusInitial>
        {{ cancelLabel }}
      </button>
      <button [disabled]="notChecked" class="primary-action-button tocBtn" (click)="signUp()" cdkFocusInitial>
        {{ buttonLabel }}
      </button>
    </div>
  </div>
</div>
