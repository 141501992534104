import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  ApiEcosystemModel,
  AuthData,
  defaultState,
  Notification,
  User,
  ContactUsData,
  LoginData,
  ExternalApiDetails,
  Application,
} from './index';
import { Inject, Injectable } from '@angular/core';
import { DataService } from '../services/data.service';
import {
  GetApiDetail,
  RemoveNotification,
  RemoveNotifications,
  SetNotification,
  SetSelectedRoute,
  SetUser,
  SetAuthData,
  ShowNavbarText,
  SetSignupData,
  GetExternalApiData,
  GetExternalApplicationsData,
  Login,
  CreateUser,
  SetSelectedApi,
  SetResetPasswordEmail,
  SendResetPasswordEmail,
  CreateApplication,
  AzureLogin,
  AzureLogout,
  SubscribeToOneApiForManyApplications,
  SetSelectedApplication,
  GetUserFromSession,
  SendContactUsEmail,
  DownloadDocument,
  UpdateUser,
  GetCachedUser,
  AddUri,
  UnSubscribeAPI,
  GetApplicationByID,
  DeleteApplication,
  GenerateClientId,
  RegenerateClientId,
  ResetSelectedApplication,
  SetClientSecret,
  ResetClientSecret,
  InitSso,
  SubscribeToManyApisForOneApplication,
  UpdateAppWebsite,
  GetContactUsCategories,
} from './api-eco.actions';
import { tap, map, catchError, filter } from 'rxjs/operators';
import { patch } from '@ngxs/store/operators';
import { SsoService } from '../services/sso.service';
import { LoginService } from '../services/login.service';
import { of } from 'rxjs';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { ProgressSpinnerDialogService } from '../services/progress-spinner-dialog.service';
import { ContextUpdateType, UserContextService } from '../services/user-context.service';
import { APIELogService } from '../services/apie-log.service';
import { BrowserStorageType } from '../services/browser-storage.service';
import { AlertMessages } from 'src/assets/data/alert-messages';
import { NavigationEnd, Router } from '@angular/router';

@State<ApiEcosystemModel>({
  name: 'apiEcosystem',
  defaults: defaultState,
})
@Injectable()
export class ApiEcoState {
  b2cPolicies: any;
  clientId: string;
  constructor(
    private service: DataService,
    private ssoService: SsoService,
    private loginService: LoginService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private location: Location,
    private authService: MsalService,
    private progressDialogService: ProgressSpinnerDialogService,
    private userContextService: UserContextService,
    private logService: APIELogService,
    private router: Router
  ) {
    this.b2cPolicies = environment.b2cPolicies;
    this.clientId = environment.clientId;
  }

  @Selector()
  static getShowNavbarText(state: ApiEcosystemModel): boolean {
    return state.showNavbarText;
  }

  // Seems it is not used anywhere but used Contact Us Interface
  @Selector()
  static getSignupData(state: ApiEcosystemModel): any {
    return state.signupData;
  }

  @Selector()
  static getLoginData(state: ApiEcosystemModel): LoginData {
    return state.loginData;
  }

  @Selector()
  static getResetPasswordEmail(state: ApiEcosystemModel): string {
    return state.resetPasswordEmail;
  }

  @Selector()
  static getSelectedRoute(state: ApiEcosystemModel): string {
    return state.selectedRoute;
  }

  @Selector()
  static getNotifications(state: ApiEcosystemModel): Notification[] {
    return state.notifications;
  }

  @Selector()
  static getAuthData(state: ApiEcosystemModel): AuthData {
    return state.authData;
  }

  @Selector()
  static getUser(state: ApiEcosystemModel): User {
    return state.user;
  }

  @Selector()
  static getApis(state: ApiEcosystemModel): ExternalApiDetails[] {
    return state.apis;
  }

  @Selector()
  static getSelectedApiDetail(state: ApiEcosystemModel): ExternalApiDetails {
    return state.selectedApiDetail;
  }

  @Selector()
  static getExternalApplications(state: ApiEcosystemModel): Application[] {
    return state.applications;
  }

  @Selector()
  static getSelectedApplicationDetail(state: ApiEcosystemModel): any {
    return state.selectedApplicationDetail;
  }

  @Selector()
  static getDownloadDocumentURI(state: ApiEcosystemModel): any {
    return state.downloadDocumentURI;
  }

  @Selector()
  static getClientSecret(state: ApiEcosystemModel): any {
    return state.clientSecret;
  }

  @Selector()
  static getContactUsCategories(state: ApiEcosystemModel): any {
    return state.contactUsCategories;
  }

  @Action(SetSelectedApplication)
  setSelectedApplication({ patchState }: StateContext<ApiEcosystemModel>, action: SetSelectedApplication) {
    patchState({ selectedApplicationDetail: action.app });
  }

  @Action(SetClientSecret)
  setClientSecret({ patchState }: StateContext<ApiEcosystemModel>, action: SetClientSecret) {
    patchState({ clientSecret: action.secret });
  }

  @Action(ResetClientSecret)
  resetClientSecret({ patchState }: StateContext<ApiEcosystemModel>) {
    patchState({
      clientSecret: null,
    });
  }

  @Action(ResetSelectedApplication)
  resetSelectedApplication({ patchState }: StateContext<ApiEcosystemModel>) {
    patchState({ selectedApplicationDetail: null });
  }
  @Action(GetExternalApiData)
  getExternalApiData({ setState, dispatch }: StateContext<ApiEcosystemModel>) {
    let currentApiId;

    // checking the url if any api is selected
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        tap((event: NavigationEnd) => {
          if (event?.url?.includes('/apis/')) {
            currentApiId = event?.url?.split('/')[2];
          }
        })
      )
      .subscribe();
    return this.service.getExternalApiData().pipe(
      tap((result: any[]) => {
        if (result) {
          try {
            let selectedApi: ExternalApiDetails;

            const publicAPI: ExternalApiDetails = result.find(
              (api: ExternalApiDetails) => api.visibility.toLocaleLowerCase() === 'public'
            );
            if (publicAPI) {
              selectedApi = publicAPI;
            }
            if (result.filter(api => api?.id === (currentApiId || this.router?.url?.split('/')[2]))[0]) {
              selectedApi = result.filter(api => api?.id === (currentApiId || this.router?.url?.split('/')[2]))[0];
            }
            console.log(selectedApi, 'selected API');
            console.log(currentApiId, this.router?.url, 'this.router?.urlthis.router?.urlthis.router?.urlthis.router?.url')
            if (selectedApi) {
              dispatch(new GetApiDetail(selectedApi.id));
            }
            setState(patch({ apis: result }));
          } catch (error) {
            this.logService.log(
              `Error retreiving API Data. Error Detail: ${error}\nPayload:\n${JSON.stringify(result, null, '\t')}... `,
              'error',
              `HTTP -> API DATA 🚨 🚨 🚨`
            );
          }
        }
      })
    );
  }

  @Action(GetExternalApplicationsData)
  getExternalApplicationsData({ patchState, getState }: StateContext<ApiEcosystemModel>) {
    return this.service.getExternalApplicationsData().pipe(
      tap((result: Application[]) => {
        patchState({
          ...getState(),
          applications: result,
        });
      })
    );
  }
  @Action(SetSelectedRoute)
  setSelectedRoute({ patchState }: StateContext<ApiEcosystemModel>, action: SetSelectedRoute) {
    patchState({ selectedRoute: action.route });
  }

  @Action(ShowNavbarText)
  toggleNavbarText({ getState, patchState }: StateContext<ApiEcosystemModel>) {
    patchState({
      showNavbarText: !getState().showNavbarText,
    });
  }

  @Action(SetSignupData)
  setSignupData({ getState, patchState }: StateContext<ApiEcosystemModel>, action: SetSignupData) {
    patchState({
      signupData: action.signupData,
    });
  }

  @Action(SetResetPasswordEmail)
  setResetPasswordEmail({ getState, patchState }: StateContext<ApiEcosystemModel>, action: SetResetPasswordEmail) {
    patchState({
      resetPasswordEmail: action.email,
    });
  }

  @Action(SendResetPasswordEmail)
  sendResetPasswordEmail(
    { getState, patchState, dispatch }: StateContext<ApiEcosystemModel>,
    action: SendResetPasswordEmail
  ) {
    return this.service.sendResetPasswordEmal(action.email).pipe(
      map(result => {
        patchState({
          resetPasswordEmail: action.email,
        });
        dispatch(
          new SetNotification({
            error: false,
            warning: false,
            message: AlertMessages.SuccessResetPassword,
            title: 'Success',
          })
        );

        dispatch(new SetSelectedRoute('home'));
        return result;
      }),
      catchError(() => {
        dispatch(
          new SetNotification({
            error: true,
            warning: false,
            message: AlertMessages.ErrorResetPassword,
            title: 'Error',
          })
        );
        return of(undefined);
      })
    );
  }

  @Action(SetNotification)
  setNotification({ getState, patchState }: StateContext<ApiEcosystemModel>, action: SetNotification) {
    console.log('in set notificatiojns');
    const notifications = [...getState().notifications];
    notifications.push(action.notification);
    patchState({
      notifications,
    });
  }

  @Action(RemoveNotification)
  removeNotification({ getState, patchState }: StateContext<ApiEcosystemModel>, action: RemoveNotification) {
    const notifications = [...getState().notifications];
    notifications.splice(
      notifications.findIndex(
        (item: Notification) =>
          item.duration === action.notification.duration &&
          item.error === action.notification.error &&
          item.title === action.notification.title &&
          item.message === action.notification.message,
        1
      )
    );
    patchState({
      notifications,
    });
  }

  @Action(RemoveNotifications)
  removeNotifications({ getState, patchState }: StateContext<ApiEcosystemModel>) {
    const notifications = [...getState().notifications];
    notifications.length = 0;
    patchState({
      notifications,
    });
  }

  @Action(SetAuthData)
  setAuthData({ getState, patchState }: StateContext<ApiEcosystemModel>, action: SetAuthData) {
    this.logService.log(`Storing authorization data for future retrieval... `, 'info', `USER_CONTEXT -> SSO AUTH`);
    this.userContextService.updateContext(ContextUpdateType.AUTH_DATA, action.authData, BrowserStorageType.ALL);

    patchState({
      authData: { ...getState().authData, ...action.authData },
    });
  }

  @Action(CreateUser)
  createUser({ getState, dispatch }: StateContext<ApiEcosystemModel>, action: CreateUser) {
    const { homeAccountId, idToken, ...newSignupData } = action.signupData;
    return this.service.createUser(newSignupData).pipe(
      map((result: any) => {
        dispatch(
          new SetNotification({
            error: false,
            warning: false,
            message: AlertMessages.SuccessUserRegistration,
            title: 'Success',
          })
        );
        dispatch(
          new Login({
            email: action.signupData.email,
            idToken,
            homeAccountId: '',
            updateUserData: null,
          })
        );
        dispatch(new SetUser(result.user));
      }),
      catchError(() => {
        dispatch(
          new SetNotification({
            error: true,
            warning: false,
            message: AlertMessages.ErrorUserRegistration,
            title: 'Error',
          })
        );
        return of(undefined);
      })
    );
  }

  @Action(AzureLogout)
  azureLogout({ getState, dispatch }: StateContext<ApiEcosystemModel>, action: AzureLogout) {
    if (getState().user.isAuthenticated) {
      this.loginService.logout().subscribe(result => {
        if (result) {
          dispatch(
            new SetUser({
              _id: 'aVal',
              email: '',
              firstName: '',
              lastName: '',
              displayName: '',
              isAuthenticated: false,
              userRole: undefined,
            })
          );
          this.authService.logout();
        }
      });
    }
  }

  @Action(AzureLogin)
  azureLogin({ getState, patchState }: StateContext<ApiEcosystemModel>, action: AzureLogin) {
    this.logService.log('Logging In to Azure', 'info', 'USER CONTEXT -> APIE STATE | LOGIN IN PROGRESS');

    const userFlowRequest = {
      ...action.userFlowRequest,
      scopes: [this.clientId],
    };
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect(userFlowRequest);
    }
  }

  @Action(SetUser)
  setUser({ getState, patchState, dispatch }: StateContext<ApiEcosystemModel>, action: SetUser) {
    patchState({
      user: { ...getState().user, ...action.user },
    });
  }

  @Action(GetUserFromSession)
  getUserFromSession({ getState, patchState, dispatch }: StateContext<ApiEcosystemModel>, action: GetUserFromSession) {
    const tokenInfo = this.userContextService.getTokenInfo();
    const savedUserToken = tokenInfo.token;

    if (savedUserToken) {
      this.logService.log(
        'Token found from existing session...',
        'success',
        `USER CONTEXT -> APIE STATE > LOGGED IN 🍺   `
      );
      const userData = {
        _id: 'aVal',
        email: '',
        userToken: savedUserToken,
      };
      dispatch(new GetCachedUser(userData));
    } else {
      // Check if current account is active. This could occur right after login before token value is set in any context.
      const currentAcount = this.authService.instance.getActiveAccount();

      if (!currentAcount) {
        this.logService.log(
          'Get user from session: No TOKEN or active account Found',
          'error',
          `USER_CONTEXT -> APIE STATE > LOGIN FAILURE 😳 `
        );
      } else {
        this.logService.log(
          'Active account already set. Getting user from cache is not requred.',
          'info',
          `USER_CONTEXT -> LOGGED IN 🤜 `
        );
      }
    }
  }

  // @Action(GetUserFromSession)
  // getUserFromSession({ getState, patchState, dispatch }: StateContext<ApiEcosystemModel>, action: GetUserFromSession) {

  //   this.userContextService.getContext().global.token;
  //   const savedUserToken = JSON.parse(sessionStorage.getItem(action.account.homeAccountId));
  //   if (savedUserToken) {
  //     const userData = {
  //       _id: 'aVal',
  //       email: '',
  //       userToken: savedUserToken
  //     };
  //     dispatch(new GetCachedUser(userData));
  //   }
  // }

  @Action(GetCachedUser)
  getCachedUser({ getState, patchState, dispatch }: StateContext<ApiEcosystemModel>, action: GetCachedUser) {
    if (action.user.userToken) {
      this.loginService.getCachedUser(action.user.userToken).subscribe(cachedUser => {
        if (cachedUser) {
          patchState({
            user: {
              ...cachedUser,
              isAuthenticated: true,
            },
          });
          dispatch(new GetExternalApplicationsData());
        }
      });
    }
  }

  @Action(GenerateClientId)
  generateClientId({ getState, patchState, dispatch }: StateContext<ApiEcosystemModel>, action: GenerateClientId) {
    if (action.generateClientIdData.appId) {
      const { isPrimary, appId } = action.generateClientIdData;
      const body = {
        environment: isPrimary ? 'primary' : 'sandbox',
      };
      if (isPrimary) {
        this.service.generateClientId(appId, body).subscribe(response => {
          if (response) {
            const selectedApp = {
              ...getState().selectedApplicationDetail,
              prodClientId: response.clientId,
              prodClientSecret: response.clientSecret,
            };
            dispatch(new SetSelectedApplication(selectedApp));
            dispatch(new GetExternalApplicationsData());
            patchState({ clientSecret: response.clientSecret });
          }
        });
      } else {
        this.service.generateClientId(appId, body).subscribe(response => {
          if (response) {
            const selectedApp = {
              ...getState().selectedApplicationDetail,
              sandboxClientId: response.clientId,
            };
            dispatch(new SetSelectedApplication(selectedApp));
            dispatch(new GetExternalApplicationsData());
            patchState({ clientSecret: response.clientSecret });
          }
        });
      }
    }
  }

  @Action(RegenerateClientId)
  regenerateClientId({ getState, patchState, dispatch }: StateContext<ApiEcosystemModel>, action: RegenerateClientId) {
    if (action.regenerateClientIdData.appId) {
      const { isPrimary, appId } = action.regenerateClientIdData;
      const body = {
        environment: isPrimary ? 'primary' : 'sandbox',
      };
      if (isPrimary) {
        this.service.regenerateClientId(appId, body).subscribe(response => {
          if (response) {
            patchState({ clientSecret: response.clientSecret });
          }
        });
      } else {
        this.service.regenerateClientId(appId, body).subscribe(response => {
          if (response) {
            patchState({ clientSecret: response.clientSecret });
          }
        });
      }
    }
  }

  @Action(UpdateUser)
  updateUser({ getState, patchState, dispatch }: StateContext<ApiEcosystemModel>, action: UpdateUser) {
    const tokenInfo = this.userContextService.getTokenInfo();
    const { homeAccountId, idToken, ...newUpdateData } = action.updateUserData;
    return this.loginService.updateUser(newUpdateData, tokenInfo.token).pipe(
      map((result: any) => {
        dispatch(
          new SetNotification({
            error: false,
            warning: false,
            message: AlertMessages.SuccessUserUpdate,
            title: 'Success',
          })
        );
        dispatch(new SetUser(result.user));
      }),
      catchError(() => {
        dispatch(
          new SetNotification({
            error: true,
            warning: false,
            message: AlertMessages.ErrorUserUpdate,
            title: 'Error',
          })
        );
        return of(undefined);
      })
    );
  }

  @Action(Login)
  login({ getState, patchState, dispatch }: StateContext<ApiEcosystemModel>, action: Login) {
    const loginData = action.loginData;
    if (loginData && loginData.email) {
      const { homeAccountId, updateUserData, ...newLoginData } = loginData;
      this.loginService.login(newLoginData).subscribe(result => {
        const newUser: User = {
          _id: '',
          id: result.id,
          email: result.email,
          firstName: result.firstName,
          lastName: result.lastName,
          displayName: result.displayName,
          country: result.country,
          isAuthenticated: true,
          userToken: result.userToken,
        };

        patchState({
          user: { ...getState().user, ...newUser },
        });

        // TODO: Not necessary - should remove after testing
        // add user token to session storage
        // this.userContextService.resetContextAfterLogin();
        // sessionStorage.clear();

        // Update before login state updated.
        const tokenExpiry = JSON.stringify(Math.floor(Date.now() / 1000) + 25 * 60);

        this.userContextService.updateContext(ContextUpdateType.TOKEN, newUser.userToken, BrowserStorageType.ALL);

        this.userContextService.updateContext(ContextUpdateType.TOKEN_EXPIRY, tokenExpiry, BrowserStorageType.ALL);
        // this.userContextService.updateContext(
        //   ContextUpdateType.ACCOUNT,
        //   newUser.,
        //   BrowserStorageType.ALL
        // );

        // sessionStorage.setItem(homeAccountId, JSON.stringify(newUser.userToken));
        // // Set token expiry to 25 min
        // sessionStorage.setItem(newUser.userToken, JSON.stringify(Math.floor(Date.now() / 1000) + (25 * 60)));

        // // set token in localStorage
        // localStorage.setItem('userToken', JSON.stringify(newUser.userToken));

        if (action.loginData.updateUserData && action.loginData.updateUserData.displayName) {
          dispatch(new UpdateUser(action.loginData.updateUserData));
        }
        patchState({ selectedRoute: 'console' });
        dispatch(new GetExternalApplicationsData());
        dispatch(new GetExternalApiData());
      });
    } else {
      patchState({ selectedRoute: 'home' });
    }
  }

  @Action(InitSso)
  initSso({ patchState, dispatch }: StateContext<ApiEcosystemModel>, action: InitSso) {
    return this.ssoService.initSSO()?.subscribe((data: any) => {
      data.Expires = data.Date;
      this.logService.log(`SSO Initilialized and authorization data returned.`, 'success', 'SSO VALID 🍺 ');
      // this.logService.log(`SSO Initilialized and authorization data returned:\n${JSON.stringify(data,null, '\t')}`,'success', 'SSO INIT 🍺 ')
      dispatch(new SetAuthData(data));
    });
  }

  @Action(SetSelectedApi)
  setSelectedApi({ getState, patchState }: StateContext<ApiEcosystemModel>, action: SetSelectedApi) {
    patchState({
      selectedApiDetail: { ...getState().selectedApiDetail, ...action.selectedApiDetail },
    });
  }

  @Action(GetApiDetail)
  getApiDetail({ getState, setState }: StateContext<ApiEcosystemModel>, action: GetApiDetail) {
    if (action.id) {
      return this.service.getApiDetail(action.id).pipe(
        tap((result: ExternalApiDetails) => {
          if (result) {
            setState(
              patch({
                selectedApiDetail: result,
              })
            );
          }
        })
      );
    } else {
      getState();
    }
  }

  @Action(SubscribeToOneApiForManyApplications)
  subscribeToOneApiForManyApplications(
    { patchState, dispatch }: StateContext<ApiEcosystemModel>,
    action: SubscribeToOneApiForManyApplications
  ) {
    return this.service.subscribeToOneApiForManyApplications(action.apiId, action.applicationIds).pipe(
      tap(result => {
        if (result && result.httpStatus !== 500) {
          dispatch(new GetExternalApplicationsData());
          patchState({ selectedRoute: 'console' });
          dispatch(
            new SetNotification({
              error: false,
              warning: false,
              message: AlertMessages.SuccessApiAdded,
              title: 'Success',
              duration: 5000,
            })
          );
        }
        if (result && result.httpStatus === 500) {
          this.progressDialogService.hideSpinner();
          dispatch(
            new SetNotification({
              message: result.message,
              error: true,
              warning: false,
              title: 'Error',
              duration: 5000,
            })
          );
        }
      })
    );
  }

  @Action(SubscribeToManyApisForOneApplication)
  subscribeToManyApisForOneApplication(
    { dispatch }: StateContext<ApiEcosystemModel>,
    action: SubscribeToManyApisForOneApplication
  ) {
    return this.service.subscribeToManyApisForOneApplication(action.applicationId, action.apis).pipe(
      tap(result => {
        if (result && result.httpStatus !== 500) {
          dispatch(new GetApplicationByID(action.applicationId));
          dispatch(
            new SetNotification({
              error: false,
              warning: false,
              message: AlertMessages.SuccessApisAdded,
              title: 'Success',
              duration: 5000,
            })
          );
        }
        if (result && result.httpStatus === 500) {
          this.progressDialogService.hideSpinner();
          dispatch(
            new SetNotification({
              message: result.message,
              error: true,
              warning: false,
              title: 'Error',
              duration: 5000,
            })
          );
        }
      })
    );
  }

  @Action(CreateApplication)
  createApplication({ getState, patchState, dispatch }: StateContext<ApiEcosystemModel>, action: CreateApplication) {
    if (action.createApplicationData) {
      return this.service.createApplication(action.createApplicationData).pipe(
        tap(result => {
          if (result) {
            dispatch(new GetExternalApplicationsData());
            patchState({ ...getState() });
          }
        })
      );
    } else {
      getState();
    }
  }

  @Action(UpdateAppWebsite)
  updateAppWebsite({ getState, dispatch }: StateContext<ApiEcosystemModel>, action: UpdateAppWebsite) {
    if (action.updateAppWebsite) {
      return this.service.updateApplicationWebsite(action.updateAppWebsite).pipe(
        tap(result => {
          if (result) {
            dispatch(new GetExternalApplicationsData());
            dispatch(new SetSelectedApplication(result));
          }
        })
      );
    }
  }

  @Action(AddUri)
  addUri({ getState, dispatch }: StateContext<ApiEcosystemModel>, action: AddUri) {
    if (action.addUriData) {
      this.progressDialogService.showSpinner();
      if (action.addUriData.isProd) {
        const { ...addUriData } = action.addUriData;
        return this.service.addProdUri(addUriData).pipe(
          tap(result => {
            if (result) {
              dispatch(new GetExternalApplicationsData());
              dispatch(new SetSelectedApplication(result));
            }
          })
        );
      } else {
        const { ...addUriData } = action.addUriData;
        return this.service.addSandboxUri(addUriData).pipe(
          tap(result => {
            if (result) {
              dispatch(new GetExternalApplicationsData());
              dispatch(new SetSelectedApplication(result));
            }
          })
        );
      }
    } else {
      getState();
    }
  }

  @Action(SendContactUsEmail)
  sendContactUsEmail({ dispatch }: StateContext<ApiEcosystemModel>, action: SendContactUsEmail) {
    return this.service.sendContactUsEmail(action.contactUsData).pipe(
      map(result => {
        dispatch(
          new SetNotification({
            error: false,
            warning: false,
            message: AlertMessages.SuccessEmail,
            title: 'Success',
          })
        );
        this.location.back();
        return result;
      }),
      catchError(() => {
        dispatch(
          new SetNotification({
            error: true,
            warning: false,
            message: AlertMessages.ErrorResetPassword,
            title: 'Error',
          })
        );
        return of(undefined);
      })
    );
  }

  @Action(DownloadDocument)
  downloadDocument({ patchState }: StateContext<ApiEcosystemModel>, action: DownloadDocument) {
    return this.service.getDownloadDocumentById(action.serviceID, action.documentID).pipe(
      tap((result: string) => {
        patchState({
          downloadDocumentURI: result,
        });
      })
    );
  }

  @Action(UnSubscribeAPI)
  unSubscribeAPI({ patchState, dispatch }: StateContext<ApiEcosystemModel>, action: UnSubscribeAPI) {
    this.progressDialogService.showSpinner();
    return this.service.unsubscribeAPI(action.appId, action.serviceId).pipe(
      tap((result: Application[]) => {
        if (result) {
          const selectedApp: Application = result.find(app => app.id === action.appId);
          if (selectedApp) {
            dispatch(new SetSelectedApplication({ ...selectedApp }));
          }
          patchState({
            applications: result,
          });
        }
        this.progressDialogService.hideSpinner();
      })
    );
  }

  @Action(GetApplicationByID)
  getApplicationByID({ patchState }: StateContext<ApiEcosystemModel>, action: GetApplicationByID) {
    return this.service.getExternalApplicationByID(action.appId).pipe(
      tap(result => {
        if (result) {
          this.progressDialogService.hideSpinner();
          patchState({
            selectedApplicationDetail: result,
          });
        }
      })
    );
  }

  @Action(DeleteApplication)
  deleteApplication({ patchState }: StateContext<ApiEcosystemModel>, action: DeleteApplication) {
    return this.service.deleteApplication(action.appId).pipe(
      tap((result: Application[]) => {
        if (result) {
          patchState({
            applications: result,
          });
        }
      })
    );
  }

  @Action(GetContactUsCategories)
  getContactUsCategories({ patchState }: StateContext<ApiEcosystemModel>, action: GetContactUsCategories) {
    return this.service.getContactUsCategories(action.supportRequestApi).pipe(
      tap(result => {
        if (result) {
          patchState({
            contactUsCategories: result,
          });
        }
      })
    );
  }
}
