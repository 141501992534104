import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Notification } from '../store';
import { AlertMessages } from '../../assets/data/alert-messages';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  toastrRef: HTMLElement;

  constructor(private toasterService: ToastrService) {}

  showErrorAlert(notification: Notification, options): void {
    if (
      !(notification.title === 'HttpErrorResponse' && notification.message.includes(AlertMessages.DuplicateAppMessage))
    )
      notification.title === 'HttpErrorResponse' &&
        (notification = { ...notification, message: AlertMessages[notification.title] });
    const errorTitle =
      notification.message && notification.message.indexOf(AlertMessages.DuplicateAppMessage) !== -1
        ? AlertMessages.DuplicateAppTitle
        : notification.title;

    this.toasterService.error(notification.message, errorTitle, options);
  }

  showWarningAlert(notification: Notification, options): void {
    this.toasterService.warning(notification.message, notification.title, options);
  }

  showSuccessAlert(notification: Notification, options): void {
    this.toasterService.success(notification.message, notification.title, options);
  }

  setAttributeForToastrForScreenReaders(): void {
    this.toastrRef = document.getElementById('toast-container');
    this.toastrRef.setAttribute('aria-live', 'polite');
  }
}
