import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { GlobalDialogComponent } from '../components/global-dialog/global-dialog.component';
import termsAndConditionsHtml from './terms-and-condition-html';
/* eslint-disable */
@Injectable()
export class GlobalDialogService {
  constructor(private dialog: MatDialog) {}
  termsAndConditionsText = termsAndConditionsHtml;

  openTermsAndConditions(event: Event): MatDialogRef<GlobalDialogComponent> {
    event.preventDefault();

    const titleOfDialog = `Terms and Conditions`;
    const contentOfDialog = this.termsAndConditionsText;
    return this.openDialog(
      titleOfDialog,
      contentOfDialog,
      'OK',
      true,
      '61em',
      '65em',
      false,
      false,
      false,
      null,
      false,
      false
    );
  }

  openSignupTermsAndConditions(event: Event): MatDialogRef<GlobalDialogComponent> {
    event.preventDefault();

    const titleOfDialog = `Terms and Conditions`;
    const contentOfDialog = this.termsAndConditionsText;

    return this.openDialog(
      titleOfDialog,
      contentOfDialog,
      'Accept',
      true,
      '61em',
      '66em',
      false,
      false,
      false,
      null,
      true,
      false
    );
  }

  openCodeOfEthics(event: Event): MatDialogRef<GlobalDialogComponent> {
    event.preventDefault();

    const titleOfDialog = `Ethical Code of Conduct`;
    const contentOfDialog = `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. <br/><br/>
    Epsum factorial non deposit quid pro quo hic escorol. Olypian quarrels et gorilla congolium sic ad nauseum. Souvlaki ignitus carborundum e pluribus unum. Defacto lingo est igpay atinlay. Marquee selectus non provisio incongruous feline nolo contendre. Gratuitous octopus niacin, sodium glutimate. Quote meon an estimate et non interruptus stadium. Sic tempus fugit esperanto hiccup estrogen. Glorious baklava ex librus hup hey ad infinitum. Non sequitur condominium facile et geranium incognito. Epsum factorial non deposit quid pro quo hic escorol. Marquee selectus non provisio incongruous feline nolo contendre Olypian quarrels et gorilla congolium sic ad nauseum. Souvlaki ignitus carborundum e pluribus unum. <br/><br/>
    Li Europan lingues es membres del sam familie. Lor separat existentie es un myth. Por scientie, musica, sport etc, li tot Europa usa li sam vocabularium. Li lingues differe solmen in li grammatica, li pronunciation e li plu commun vocabules. Omnicos directe al desirabilita; de un nov lingua franca: on refusa continuar payar custosi traductores. It solmen va esser necessi far uniform grammatica, pronunciation e plu sommun paroles. <br/><br/>
    Ma quande lingues coalesce, li grammatica del resultant lingue es plu simplic e regulari quam ti del coalescent lingues. Li nov lingua franca va esser plu simplic e regulari quam li existent Europan lingues. It va esser tam simplic quam Occidental: in fact, it va esser Occidental. A un Angleso it va semblar un simplificat Angles, quam un skeptic Cambridge amico dit me que Occidental es.<br/><br/>
    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.<br/><br/>
    Epsum factorial non deposit quid pro quo hic escorol. Olypian quarrels et gorilla congolium sic ad nauseum. Souvlaki ignitus carborundum e pluribus unum. Defacto lingo est igpay atinlay. Marquee selectus non provisio incongruous feline nolo contendre. Gratuitous octopus niacin, sodium glutimate. Quote meon an estimate et non interruptus stadium. Sic tempus fugit esperanto hiccup estrogen. Glorious baklava ex librus hup hey ad infinitum. Non sequitur condominium facile et geranium incognito. Epsum factorial non deposit quid pro quo hic escorol. Marquee selectus non provisio incongruous feline nolo contendre Olypian quarrels et gorilla congolium sic ad nauseum. Souvlaki ignitus carborundum e pluribus unum.<br/><br/>
    Li Europan lingues es membres del sam familie. Lor separat existentie es un myth. Por scientie, musica, sport etc, li tot Europa usa li sam vocabularium. Li lingues differe solmen in li grammatica, li pronunciation e li plu commun vocabules. Omnicos directe al desirabilita; de un nov lingua franca: on refusa continuar payar custosi traductores. It solmen va esser necessi far uniform grammatica, pronunciation e plu sommun paroles. <br/><br/>`;

    return this.openDialog(
      titleOfDialog,
      contentOfDialog,
      'OK',
      true,
      '61em',
      '63em',
      false,
      false,
      false,
      null,
      false,
      false
    );
  }

  openAccessibility(event: Event): MatDialogRef<GlobalDialogComponent> {
    event.preventDefault();

    const titleOfDialog = `Accessibility`;
    const contentOfDialog = `We're dedicated to making our website accessible to everyone. We continually review and modify our site to improve its accessibility for people with disabilities, and those who use assistive technology.<br/><br/>
    Kaiser Permanente adheres to the accessibility standards of WCAG 2.0 conformance level A, and level AA (WCAG 2.0 AA) to meet the Americans with Disabilities Act, Section 508 of the Rehabilitation Act, and various state laws.<br/><br/>
    Please note that Kaiser Permanente needs to support present-day technical accessibility standards, such as ARIA 1.1, to ensure our interactive web features work correctly with modern browsers and assistive technology. Because Internet Explorer (IE11) does not support these technical standards, we strongly recommend using another browser if you are using assistive technology. We recommend several screen reader – browser combinations including…<br/><br/>
    <ul><li>JAWS / Chrome (Windows)</li></ul>
    <br/>
    For further information, please call the general Web Site Support line 1-800-556-7677 (TTY 711), available 24/7 excluding major holidays.
    </br></br>`;

    return this.openDialog(titleOfDialog, contentOfDialog, 'OK', false, '71em', '43em');
  }

  openPrivacy(event: Event): MatDialogRef<GlobalDialogComponent> {
    event.preventDefault();

    const titleOfDialog = `Website Privacy`;
    const contentOfDialog = `</br></br></br></br></br></br>NEED COPY FOR THIS STILL</br></br></br></br></br></br></br></br>`;

    return this.openDialog(titleOfDialog, contentOfDialog, 'OK', false, '71em', '43em');
  }

  deleteApplicationConfirmation(event: Event): MatDialogRef<GlobalDialogComponent> {
    event.preventDefault();

    const titleOfDialog = `Delete Application Confirmation`;
    const contentOfDialog = `<div style="display:flex;flex:1;align-items:center;justify-content:center;height:5em">
    <span>Are you sure you want to delete your application? This action cannot be undone.</span>
    </div>`;

    return this.openDialog(
      titleOfDialog,
      contentOfDialog,
      'Delete',
      false,
      '68em',
      '18em',
      true,
      true,
      true,
      'error_outline'
    );
  }

  removeAPIConfirmation(event: Event): MatDialogRef<GlobalDialogComponent> {
    event.preventDefault();

    const titleOfDialog = `Remove API Confirmation`;
    const contentOfDialog = `<div style="display:flex;flex:1;align-items:center;justify-content:center;height:6em">
    <span>Are you sure you want to remove this API from your application?</span>
    </div>`;

    return this.openDialog(
      titleOfDialog,
      contentOfDialog,
      'Remove',
      false,
      '48em',
      '19em',
      true,
      true,
      true,
      'error_outline'
    );
  }

  openDialog(
    titleOfDialog: string,
    contentOfDialog: string,
    buttonLabel: string,
    showDownload: boolean = true,
    dialogWidth: string = '955px',
    dialogHeight: string = '885px',
    showCancel?: boolean,
    showButtonsRight?: boolean,
    showIcon?: boolean,
    iconName?: string,
    showSignup?: boolean,
    centerContent: boolean = true
  ): MatDialogRef<GlobalDialogComponent> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = dialogWidth;
    dialogConfig.height = dialogHeight;
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title: titleOfDialog,
      content: contentOfDialog,
      buttonLabel,
      showDownload,
      showCancel,
      showButtonsRight,
      showIcon,
      iconName,
      showSignup,
      centerContent,
    };

    return this.dialog.open(GlobalDialogComponent, dialogConfig);
  }
}
