<div class="footer-main" role="contentinfo">
  <div class="footer-links-div">
    <a href="javascript.void()" (click)="onAccessibility($event)">Accessibility</a>
    <span class="footer-link-separator">|</span>
    <a href="https://healthy.kaiserpermanente.org/privacy" target="_blank">Website Privacy</a>
    <span class="footer-link-separator">|</span>
    <a href="https://healthy.kaiserpermanente.org" target="_blank">kp.org</a>
  </div>
  <div class="footer-text-div">
    <p class="footer-bottom-text">Adobe Acrobat Reader is required to read PDFs.</p>

    <p class="footer-bottom-text">© {{ currentYear }} Kaiser Foundation Health Plan, Inc.</p>
  </div>
</div>
